import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { authActions } from "../../redux/sagas/authSaga";
import Button from "../../front-end-global-components/components/Button/Button";
import { reCaptchaVerifier } from "../../services/authentication";
import OTPInputBox from "../../front-end-global-components/components/OTPInputBox/OTPInputBox";
import InputBox from "../../front-end-global-components/components/InputBox/InputBox";
import Header from "../../front-end-global-components/components/Header/Header";
import ConditionalRender from "../../front-end-global-components/components/ConditionalRender/ConditionalRender";
import { mockActions } from "../../redux/sagas/mockSaga";
import { statusActions } from "../../redux/sagas/statusSaga";

function SignUp(props) {
  const [signUpFormData, setSignUpFormData] = useState({
    userName: "",
    phoneNumber: "+91",
    OTP: null
  });

  //invisible recaptcha for authentication
  useEffect(() => {
    window.reCaptchaVerifier = reCaptchaVerifier();
  }, []);

  const setOTPValue = (otp) => {
    if (signUpFormData.phoneNumber === null) {
      return;
    }
    setSignUpFormData({
      ...signUpFormData,
      OTP: otp
    });
  };

  const signUpFormOnChange = (event) => {
    //To clear auth verification while user trying to change phone number after otp sent
    if (
      event.target.name === "phoneNumber" &&
      props.auth.credentials.verificationId &&
      signUpFormData.phoneNumber !== event.target.value
    ) {
      //clear auth verification
      props.clearCredentials();
    }
    if (event.target.name.includes("otp")) {
      return;
    } else if (event.target.name === "termsAndConditions") {
      setSignUpFormData({
        ...signUpFormData,
        [event.target.name]: event.target.checked
      });
    } else {
      setSignUpFormData({
        ...signUpFormData,
        [event.target.name]: event.target.value
      });
    }
  };

  const otpRequestHandler = (event) => {
    event.preventDefault();
    if (signUpFormData.userName.trim().length === 0) {
      return props.setErrorStatus({
        code: "custom",
        message: "Please enter a valid name"
      });
    } else {
      props.sendOtp(signUpFormData.phoneNumber, "signUp");
    }
  };

  const signUpHandler = (event) => {
    event.preventDefault();
    if (process.env.REACT_APP_STAGING === "local") {
      props.mockAuthVerifyOtp(
        signUpFormData.OTP,
        signUpFormData.userName,
        signUpFormData.phoneNumber,
        "signUp"
      );
    } else {
      props.verifyOtp(
        signUpFormData.OTP,
        signUpFormData.userName,
        signUpFormData.phoneNumber,
        "signUp"
      );
    }
  };

  return (
    <div className="inherit-parent-height flex-place-children-page-center">
      <div className="inherit-parent-height inherit-parent-width large-screen-bg-color large-screen-height large-screen-width large-screen-border">
        <Header
          removeResponsive={true}
          userType="corporate"
          backButtonOnClick={() => {}}
          hideBackButton
          maxStepperCount={3}
          currentStepperValue={0}
          supportIconOnClick={() => {}}
        />
        <article className="inherit-parent-height inherit-parent-width large-screen-padding">
          <form
            data-cy="signup"
            className="remaining-body-height overflow-x-scroll hide-scrollbar width-100-percent grey-abstract-background-image flex-center-children-vertically flex-direction-column"
            onChange={(event) => {
              signUpFormOnChange(event);
            }}
            onSubmit={(event) => event.preventDefault()}
          >
            <main className="padding-larger padding-bottom-0 inherit-parent-height inherit-parent-width max-width-500px  flex-grow">
              <h1 className="font-weight-normal font-family-gilroy-medium font-size-small font-color-secondary inherit-parent-width text-align-center">
                CLINIC SIGN UP
              </h1>
              <InputBox
                className="inherit-parent-width border-radius-default margin-vertical-large"
                name="userName"
                removeResponsive={true}
                label="Owner Name"
                value={signUpFormData.userName}
                type="text"
                autoComplete="on"
                required
                data-cy="signup-screen-name-field"
              />
              <InputBox
                className="inherit-parent-width border-radius-default margin-bottom-large"
                name="phoneNumber"
                label="Phone Number"
                value={signUpFormData.phoneNumber}
                removeResponsive={true}
                type="tel"
                autoComplete="off"
                show="otpButton"
                buttonTileId="login-button"
                onButtonClick={(event) => {
                  otpRequestHandler(event);
                }}
                data-cy="signup-screen-phone-number-field"
                suffixButtonDisabled={signUpFormData.phoneNumber?.length !== 13}
                buttonTile={
                  props.auth.credentials.verificationId !== null
                    ? "Resend OTP"
                    : "Get OTP"
                }
                buttonType={
                  typeof props.auth.credentials.verificationId === "string"
                    ? "button"
                    : "submit"
                }
                required
              />
              <ConditionalRender
                condition={
                  typeof props.auth.credentials.verificationId === "string"
                }
              >
                <OTPInputBox
                  name="otp"
                  setValue={setOTPValue}
                  className="margin-bottom-large"
                  required
                />
              </ConditionalRender>
            </main>
            <footer className="padding-larger inherit-parent-width max-width-500px">
              <Button
                loading={props.auth.loading}
                type={
                  typeof props.auth.credentials.verificationId === "string"
                    ? "submit"
                    : "button"
                }
                className="margin-bottom-large"
                text="Signup"
                onClick={(event) => {
                  signUpHandler(event);
                }}
                boxShadow={false}
                disabled={
                  !(
                    typeof signUpFormData.OTP === "string" &&
                    typeof signUpFormData.userName === "string" &&
                    signUpFormData.userName &&
                    signUpFormData.OTP.length === 6
                  )
                }
                data-cy={
                  props.auth.loading === true
                    ? "signup-loading-button"
                    : "signup-button"
                }
              />
              <div className="font-weight-normal flex-center-children-horizontally  font-family-gilroy-medium font-size-small font-color-secondary inherit-parent-width text-align-center">
                Already have an account?
                <div
                  data-cy="login-page-link"
                  className={`margin-left-default font-color-primary ${
                    props.auth.loading === true
                      ? "pointer-events-none"
                      : "cursor-pointer"
                  }`}
                  onClick={() => {
                    props.navigate("/login");
                    props.clearCredentials();
                  }}
                >
                  Log in
                </div>
              </div>
            </footer>
          </form>
          <div id="recaptcha-placeholder"></div>
        </article>
      </div>
    </div>
  );
}

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    profile: state.profile
  };
};

const mapDispatchToProps = function () {
  return {
    verifyOtp: (otp, userName, phoneNumber, type) =>
      authActions.verifyOtp(otp, userName, phoneNumber, type),
    sendOtp: (phoneNumber, type) => authActions.sendOtp(phoneNumber, type),
    mockAuthVerifyOtp: (otp, userName, phoneNumber, type) =>
      mockActions.mockAuthVerifyOtp(otp, userName, phoneNumber, type),
    clearCredentials: () => authActions.clearCredentials(),
    setErrorStatus: (errorObj) => {
      statusActions.setErrorStatus(errorObj);
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
