import React, { useState } from "react";
import "./Setting.css";
import { connect } from "react-redux";
import Header from "../../front-end-global-components/components/Header/Header";
import logout from "../../front-end-global-components/assets/logout.svg";
import ProfileCards from "../../components/ProfileCards/ProfileCards";
import { UserIcon, StatusIcon, OptionIcon } from "../../assets/assets";
import ConditionalRender from "../../front-end-global-components/components/ConditionalRender/ConditionalRender";
import { connectionActions } from "../../redux/sagas/connectionSaga";
import RoundedProfilePicture from "../../front-end-global-components/components/RoundedProfilePicture/RoundedProfilePicture";
import { isValidObject } from "../../utils/validators";
import hospital from "../../assets/icons/Hospital.svg";
import Button from "../../front-end-global-components/components/Button/Button";
import Modal from "../../front-end-global-components/components/Modal/Modal";
// import arrow from "../../front-end-global-components/assets/DropDownArrow.svg";
import { statusActions } from "../../redux/sagas/statusSaga";
import { useEffect } from "react";

function Setting(props) {
  const [selected, setSelected] = useState("user");
  // const [showOption, setShowOption] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (isValidObject(props.connection.brands)) {
      props.getCorporatePackages(Object.keys(props.connection.brands));
    }
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <div className="inherit-parent-height flex-place-children-page-center ">
        <div className="inherit-parent-height inherit-parent-width large-screen-bg-color large-screen-height large-screen-width large-screen-border">
          <Header
            removeResponsive={true}
            title="Settings"
            backButtonOnClick={() => {
              props.navigate("/");
            }}
            supportIconOnClick={() => {}}
          />
          <div className="remaining-body-height overflow-x-scroll hide-scrollbar width-100-percent grey-abstract-background-image flex-center-children-vertically flex-direction-column">
            <div className="padding-top-larger inherit-parent-width">
              <UserCard
                selected={selected}
                data={
                  props.profile.data &&
                  props.profile.data[Object.keys(props.profile.data)[0]]
                }
                connection={props.connection}
                userOnClick={() => {
                  setSelected("user");
                }}
                statusOnClick={() => {
                  setSelected("status");
                }}
              />
            </div>
            <ConditionalRender condition={selected === "status"}>
              <div className="padding-right-larger padding-left-larger padding-bottom-larger inherit-parent-height inherit-parent-width">
                <div className="flex-center-children-horizontally margin-vertical-larger">
                  <div className=" font-color-secondary">
                    PARTNERED COMPANIES
                  </div>
                </div>
                <div className=" inherit-parent-width">
                  <ConditionalRender condition={props.connection.loading}>
                    <PartneredCompaniesSuspense />
                  </ConditionalRender>
                  <ConditionalRender
                    condition={
                      !props.connection.loading &&
                      isValidObject(props.connection.corporatePackages)
                    }
                  >
                    {props.connection.corporatePackages &&
                      Object.values(props.connection.corporatePackages).map(
                        (data, index) => (
                          <div className="margin-bottom-large" key={index}>
                            {!Object.keys(data).includes("accepted") && (
                              <PartneredClinicCard
                                userType={
                                  props.connection.currentConnection?.userType
                                }
                                data={data}
                                connection={props.connection}
                                onOptionClick={() => {
                                  // setShowOption(true);
                                  // setSelectedOption(data);
                                }}
                                requestOnClick={() => {
                                  if (
                                    props.connection.currentConnection
                                      ?.userType === "owner"
                                  ) {
                                    setShowModal(true);
                                    setSelectedOption(data);
                                  }
                                }}
                              />
                            )}
                          </div>
                        )
                      )}
                    {props.connection.corporatePackages &&
                      Object.values(props.connection.corporatePackages).map(
                        (data, index) => (
                          <div className=" margin-bottom-large" key={index}>
                            {Object.keys(data).includes("accepted") && (
                              <PartneredClinicCard
                                userType={
                                  props.connection.currentConnection?.userType
                                }
                                data={data}
                                connection={props.connection}
                                onOptionClick={() => {
                                  // setShowOption(true);
                                  // setSelectedOption(data);
                                }}
                                requestOnClick={() => {
                                  if (
                                    props.connection.currentConnection
                                      ?.userType === "owner"
                                  ) {
                                    setShowModal(true);
                                    setSelectedOption(data);
                                  }
                                }}
                              />
                            )}
                          </div>
                        )
                      )}
                  </ConditionalRender>
                  <ConditionalRender
                    condition={
                      !isValidObject(props.connection.corporatePackages)
                    }
                  >
                    <NoClinicFound
                      addClinicOnCliCk={() => {
                        props.navigate("/addclinic");
                      }}
                    />
                  </ConditionalRender>
                </div>
              </div>
            </ConditionalRender>
            <ConditionalRender condition={selected === "user"}>
              <div className="padding-right-larger padding-left-larger padding-bottom-larger inherit-parent-height inherit-parent-width flex-justify-content-space-between flex-direction-column">
                <div className="">
                  <div className=" text-align-center font-color-secondary padding-vertical-larger">
                    MANAGE CLINICS
                  </div>
                  <div className=" inherit-parent-width">
                    {props.connection.currentConnection &&
                      props.connection.data.map((data, index) => (
                        <ProfileCards
                          connection={props.connection}
                          key={index}
                          data={data}
                          userType={
                            props.connection.currentConnection?.userType
                          }
                          selected={false}
                          onCardClick={() => {
                            if (
                              props.connection.currentConnection?.userType ===
                                "owner" ||
                              props.connection.currentConnection?.userType ===
                                "manager"
                            ) {
                              if (data.verified === true) {
                                props.navigate(
                                  `/personnel/${data.connectionId}`
                                );
                              } else {
                                statusActions.setErrorStatus({
                                  code: "custom",
                                  message: "Selected clinic has`t been verified"
                                });
                              }
                            }
                          }}
                        />
                      ))}
                    <div className=" padding-top-default">
                      <ProfileCards
                        type="add"
                        text="Add Clinic"
                        addOnClick={() => {
                          props.navigate("/create");
                        }}
                      />
                    </div>
                  </div>
                </div>
                <footer
                  data-cy="logout-button"
                  className="flex-justify-content-center flex-center-children-vertically"
                >
                  <div
                    className=" display-flex cursor-pointer"
                    onClick={props.logoutOnClick}
                  >
                    <img height={13} width={14} src={logout} alt="logout" />
                    <div className="padding-left-default font-color-red font-size-large">
                      Logout
                    </div>
                  </div>
                </footer>
              </div>
            </ConditionalRender>
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        canIgnore={true}
        onClose={() => {
          setShowModal(!showModal);
        }}
        position={
          props.windowInnerWidth > 576
            ? ""
            : "position-fixed bottom-0 left-0 right-0"
        }
        width="inherit-parent-width"
        maxWidth={props.windowInnerWidth < 576 ? "" : "max-width-500px"}
        background="false"
        boxShadow="false"
        borderRadius="false"
        height="height-fit-to-content"
      >
        <div
          data-cy="request-received-modal"
          className="background-white padding-larger border-radius-default box-shadow-default font-family-gilroy-regular font-color-secondary flex-direction-column flex-center-children-vertically "
        >
          <div className="text-align-center">New request to partner</div>
          <div className=" font-size-small padding-top-large text-align-center">
            {selectedOption?.corporateName} wanted to partner with{" "}
            {selectedOption?.clinicName}.
          </div>
          <div className=" padding-vertical-large">
            <div className=" display-flex flex-center-children-vertically ">
              <RoundedProfilePicture
                size="medium"
                removeResponsive={true}
                src={
                  selectedOption?.downloadURL
                    ? selectedOption.downloadURL
                    : props.connection?.corporateBrands &&
                      props.connection?.corporateBrands[
                        selectedOption?.corporateBrandId
                      ]?.downloadURL
                }
              />
              <div className=" padding-left-default">
                {selectedOption?.corporateName}
              </div>
            </div>
            <div className=" width-44px flex-direction-column flex-center-children-vertically padding-bottom-default">
              <div className="background-color-4E4E4E height-5px width-1px margin-top-small border-radius-default" />
              <div className="background-color-4E4E4E height-5px width-1px margin-top-small border-radius-default" />
              <div className="background-color-4E4E4E height-5px width-1px margin-top-small border-radius-default" />
            </div>
            <div className=" display-flex  flex-center-children-vertically ">
              <RoundedProfilePicture
                size="medium"
                removeResponsive={true}
                src={
                  selectedOption?.downloadURL
                    ? selectedOption?.downloadURL
                    : props.connection?.brands &&
                      props.connection?.brands[selectedOption?.clinicBrandId]
                        ?.downloadURL
                }
              />
              <div className=" padding-left-default">
                {selectedOption?.clinicName}
              </div>
            </div>
            <div />
          </div>
          <div className="display-flex inherit-parent-width">
            <Button
              boxShadow={false}
              data-cy="approve-button"
              loading={props.logoutButtonLoading}
              className="margin-right-small font-family-gilroy-medium"
              variant="primary"
              text="Approve"
              onClick={() => {
                setShowModal(!showModal);
                props.modifyCorporatePackages({
                  corporatePackageId: selectedOption.documentId,
                  accepted: true
                });
              }}
              type="button"
            />
            <Button
              boxShadow={false}
              data-cy="deny-button"
              className="margin-left-small font-family-gilroy-medium font-color-red"
              variant="secondary"
              text="Deny"
              onClick={() => {
                setShowModal(!showModal);
                props.modifyCorporatePackages({
                  corporatePackageId: selectedOption.documentId,
                  accepted: false
                });
              }}
              type="button"
            />
          </div>
        </div>
      </Modal>
    </>
  );
}

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    profile: state.profile,
    connection: state.connection
  };
};

const mapDispatchToProps = function () {
  return {
    modifyCorporatePackages: (data) =>
      connectionActions.modifyCorporatePackages(data),
    getCorporatePackages: (data) => connectionActions.getCorporatePackages(data)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Setting);

const UserCard = (props) => {
  return (
    <div className="border-bottom-1px-lite-grey">
      <div className="padding-right-larger padding-left-larger padding-bottom-larger">
        <div className=" font-size-large font-family-gilroy-medium text-capitalize ">
          {props.data?.fullName
            ? props.data?.fullName
            : props.data?.phoneNumber}
        </div>
        <div className=" font-size-small font-family-gilroy-medium padding-vertical-default">
          {props.data?.phoneNumber}
        </div>
        <div className="display-flex flex-justify-content-space-between">
          <div className="background-color-grey font-size-small padding-default height-fit-to-content width-fit-content border-radius-default text-capitalize">
            {props.connection.currentConnection?.userType}
          </div>
          <div className="display-flex">
            <div className="margin-left-default">
              <div
                data-cy="userIcon"
                className={`${
                  props.selected === "user" ? "background-color-grey" : ""
                } padding-default display-flex flex-center-children-vertically  border-radius-default height-fit-content width-fit-content cursor-pointer`}
                onClick={props.userOnClick}
              >
                <UserIcon
                  color={`${props.selected === "user" ? "#00A000" : "#4E4E4E"}`}
                />
              </div>
              <ConditionalRender condition={props.selected === "user"}>
                <div className=" background-color-primary height-2px border-position-bottom" />
              </ConditionalRender>
            </div>
            <div className="margin-left-default">
              <div
                data-cy="statusIcon"
                className={`${
                  props.selected === "status" ? "background-color-grey" : ""
                } padding-default display-flex flex-center-children-vertically  border-radius-default height-fit-content width-fit-content cursor-pointer`}
                onClick={props.statusOnClick}
              >
                <StatusIcon
                  color={`${
                    props.selected === "status" ? "#00A000" : "#4E4E4E"
                  }`}
                />
              </div>
              <ConditionalRender condition={props.selected === "status"}>
                <div className=" background-color-primary height-2px border-position-bottom" />
              </ConditionalRender>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const PartneredClinicCard = (props) => {
  return (
    <div
      onClick={
        props.userType === "owner" &&
        (props.data.accepted === true || props.data.accepted === false)
          ? props.onOptionClick
          : props.requestOnClick
      }
      data-cy={`${props.data.documentId}-${
        props.data && Object.keys(props.data).includes("accepted")
          ? props.data.accepted
            ? "Approved"
            : "Denied"
          : "Pending"
      }-card`}
      className="inherit-parent-width display-flex flex-center-children-vertically flex-justify-content-space-between"
    >
      <div className=" display-flex flex-center-children-vertically">
        <RoundedProfilePicture
          size="medium"
          userType="corporate"
          removeResponsive={true}
          src={
            props.data?.downloadURL
              ? props.data.downloadURL
              : props.connection?.corporateBrands &&
                props.connection?.corporateBrands[props.data?.corporateBrandId]
                  ?.downloadURL
          }
        />
        <div className=" padding-left-default">
          <div>{props.data.corporateName}</div>
          <div className=" font-size-small">
            {props.data && Object.keys(props.data).includes("accepted")
              ? props.data.accepted
                ? `Partnered with ${props.data.clinicName}`
                : ""
              : `wants to partner with ${props.data.clinicName}`}
          </div>
          <div
            className={`${
              props.data && Object.keys(props.data).includes("accepted")
                ? props.data.accepted
                  ? "background-color-accepted"
                  : "background-color-rejected"
                : "background-color-pending"
            }  border-radius-default width-fit-content padding-left-default padding-right-default padding-top-small padding-bottom-small`}
          >
            <div
              className={`${
                props.data && Object.keys(props.data).includes("accepted")
                  ? props.data.accepted
                    ? "font-color-primary"
                    : "font-color-red"
                  : "font-color-pending"
              } font-size-small`}
            >
              {props.data && Object.keys(props.data).includes("accepted")
                ? props.data.accepted
                  ? "Approved"
                  : "Denied"
                : "Pending"}
            </div>
          </div>
        </div>
      </div>
      <ConditionalRender
        condition={
          props.userType === "owner" &&
          props.data &&
          !Object.keys(props.data).includes("accepted")
        }
      >
        <div
          data-cy="options-icon"
          className={`${props.userType === "owner" && "cursor-pointer"}`}
        >
          <OptionIcon />
        </div>
      </ConditionalRender>
      {/* for option in cards */}
      {/* <ConditionalRender
        condition={
          props.userType === "owner" &&
          !Object.keys(props.data).includes("accepted")
        }
      >
        <img
          className="margin-right-default rotate-90"
          src={arrow}
          alt="arrow"
        />
      </ConditionalRender> */}
    </div>
  );
};

const NoClinicFound = (props) => {
  return (
    <div className=" inherit-parent-width inherit-parent-height padding-top-larger flex-direction-column flex-center-children-horizontally flex-center-children-vertically">
      <img height="135px" weight="95px" src={hospital} alt="hospital" />
      <div className="padding-top-larger font-family-gilroy-medium text-align-center">
        No company available
      </div>
      <div className="padding-top-larger text-align-center">
        Your clinic(s) does not have any partnered company. You will receive the
        requests from partnered company here.
      </div>
    </div>
  );
};

const PartneredCompaniesSuspense = () => {
  return (
    <div className=" inherit-parent-height" data-cy="view-filled-form">
      {[...Array(9)].map((data, index) => (
        <div
          key={index}
          className=" shimmer width-90-percent padding-larger border-radius-default margin-bottom-large"
        ></div>
      ))}
    </div>
  );
};
