import store from "../store/store";
import { put, takeEvery, all } from "redux-saga/effects";
import { statusActions } from "./statusSaga";
import { updateCheckupStatus } from "../../services/database";
import { isOnline, isValidArray, isValidObject } from "../../utils/validators";
import { getFileUrl } from "../../services/storage";
import { throwError } from "../../services/error";
import { deleteDocument, uploadDocument } from "../../services/api";

export const actionTypes = {
  SET_CORPORATE_CHECKUPS: "SET_CORPORATE_CHECKUPS",
  SET_SELECTED_CHECKUP: "SET_SELECTED_CHECKUP",
  UPDATE_CHECKUP_STATUS: "UPDATE_CHECKUP_STATUS",
  UPLOAD_REPORT: "UPLOAD_REPORT",
  DELETE_REPORT: "DELETE_REPORT",
  GET_DOWNLOAD_URL_FOR_REPORT: "GET_DOWNLOAD_URL_FOR_REPORT",
  CLEAR_SELECTED_REQUEST: "CLEAR_SELECTED_REQUEST"
};

export const corporateActions = {
  setCorporateCheckups: (data) => {
    store.dispatch({
      type: actionTypes.SET_CORPORATE_CHECKUPS,
      payload: {
        data: data
      }
    });
  },

  setSelectedRequest: (data) => {
    store.dispatch({
      type: actionTypes.SET_SELECTED_CHECKUP,
      payload: {
        data: data
      }
    });
  },

  updateCheckupRequest: (data) => {
    store.dispatch({
      type: actionTypes.UPDATE_CHECKUP_STATUS,
      payload: {
        data: data
      }
    });
  },

  uploadReport: (data) => {
    store.dispatch({
      type: actionTypes.UPLOAD_REPORT,
      payload: {
        data: data
      }
    });
  },

  deleteReport: () => {
    store.dispatch({
      type: actionTypes.DELETE_REPORT
    });
  },

  getReportDownloadAbleURL: (data) => {
    store.dispatch({
      type: actionTypes.GET_DOWNLOAD_URL_FOR_REPORT,
      payload: {
        data: data
      }
    });
  },

  clearSelectedRequest: () => {
    store.dispatch({
      type: actionTypes.CLEAR_SELECTED_REQUEST
    });
  }
};

function* setCorporateCheckupsWorker(action) {
  try {
    yield setCorporateLoading(true);
    yield put({
      type: "SET_CORPORATE_CHECKUPS_DATA",
      payload: {
        data: action.payload.data
      }
    });
    yield setCorporateLoading(false);
  } catch (error) {
    yield statusActions.setErrorStatus(error);
    yield setCorporateLoading(false);
  }
}

function* setSelectedRequestWorker(action) {
  try {
    yield setCorporateLoading(true);
    yield put({
      type: "SET_SELECTED_CHECKUP_DATA",
      payload: {
        data: action.payload.data
      }
    });
    yield setCorporateLoading(false);
  } catch (error) {
    yield statusActions.setErrorStatus(error);
    yield setCorporateLoading(false);
  }
}

function* updateCheckUpStatusWorker(action) {
  try {
    if (isOnline()) {
      yield setCorporateLoading(true);
      yield updateCheckupStatus(action.payload.data.id, {
        status: action.payload.data.status
      });
      statusActions.setSuccessStatus("Health checkup completed");
      yield setCorporateLoading(false);
    }
  } catch (error) {
    yield statusActions.setErrorStatus(error);
    yield setCorporateLoading(false);
  }
}

function* uploadReportWorker(action) {
  try {
    if (isOnline()) {
      yield setCorporateLoading(true);
      const clinicId =
        store.getState().connection.currentConnection.connectionId;
      const selectedRequestId = store.getState().corporate.selectedRequest;

      const selectedRequestData = store
        .getState()
        .corporate.checkups.find(
          (data) => data.requestId === selectedRequestId
        );

      let data = new FormData();

      if (action.payload.data.type === "image") {
        if (isValidArray(action.payload.data.file)) {
          for (let i = 0; i < action.payload.data.file.length; i++) {
            data.append("files", action.payload.data.file[i]);
          }
          data.append("clinicId", clinicId);
          data.append("documentType", "DiagnosticReport");
          data.append("demographicDocumentId", selectedRequestData.patientId);
          data.append("fileConversion", true);
          data.append("checkupId", selectedRequestId);

          yield uploadDocument(data);

          statusActions.setSuccessStatus("Report uploaded successfully");
        } else {
          throw throwError("custom", "Invalid File");
        }
      } else {
        data.append("files", action.payload.data.file);
        data.append("clinicId", clinicId);
        data.append("documentType", "DiagnosticReport");
        data.append("demographicDocumentId", selectedRequestData.patientId);
        data.append("fileConversion", true);
        data.append("checkupId", selectedRequestId);

        yield uploadDocument(data);

        statusActions.setSuccessStatus("Report uploaded successfully");
      }
      yield setCorporateLoading(false);
    }
  } catch (error) {
    yield statusActions.setErrorStatus(error);
    yield setCorporateLoading(false);
  }
}

function* deleteReportWorker(action) {
  try {
    if (isOnline()) {
      yield setCorporateLoading(true);
      const selectedRequestId = store.getState().corporate.selectedRequest;

      const selectedRequestData = store
        .getState()
        .corporate.checkups.find(
          (data) => data.requestId === selectedRequestId
        );

      if (isValidObject(selectedRequestData)) {
        yield deleteDocument(selectedRequestData?.documentId);
      }
      statusActions.setSuccessStatus("Report deleted successfully");
      yield setCorporateLoading(false);
    }
  } catch (error) {
    yield statusActions.setErrorStatus(error);
    yield setCorporateLoading(false);
  }
}

function* getDownloadURLForReportWorker(action) {
  try {
    if (isOnline()) {
      let URL;
      const fileName = "default.json";
      const fileType = "DiagnosticReport";
      const documentId = action.payload.data.documentId;
      const clinicId = action.payload.data.clinicId;
      const patientDemographicDocumentId = action.payload.data.patientId;
      URL = yield getFileUrl(
        action.payload.data.url === undefined
          ? `gs://dev-ninto-documents-storage-1/documents/${clinicId}/${patientDemographicDocumentId}/${fileType}/${documentId}/${fileName}`
          : action.payload.data.url
      );

      yield put({
        type: "SET_CHECKUP_DOWNLOADABLE_URL",
        payload: {
          data: {
            ...(typeof URL === "string" ? { [documentId]: URL } : {})
          }
        }
      });
    }
  } catch (error) {
    yield statusActions.setErrorStatus(error);
  }
}

function* clearSelectedRequestWorker() {
  yield put({
    type: "RESET_SELECTED_REQUEST"
  });
}

export function* corporateWatcher() {
  yield all([
    takeEvery("SET_CORPORATE_CHECKUPS", setCorporateCheckupsWorker),
    takeEvery("SET_SELECTED_CHECKUP", setSelectedRequestWorker),
    takeEvery("UPDATE_CHECKUP_STATUS", updateCheckUpStatusWorker),
    takeEvery("UPLOAD_REPORT", uploadReportWorker),
    takeEvery("DELETE_REPORT", deleteReportWorker),
    takeEvery("GET_DOWNLOAD_URL_FOR_REPORT", getDownloadURLForReportWorker),
    takeEvery("CLEAR_SELECTED_REQUEST", clearSelectedRequestWorker)
  ]);
}

function* setCorporateLoading(bool) {
  yield put({
    type: "SET_CORPORATE_LOADING",
    payload: {
      loading: bool
    }
  });
}
