import { all, put, takeEvery } from "@redux-saga/core/effects";
import {
  addConnectionsToProfile,
  createClinicBrand,
  createClinicProfile,
  createProfile,
  editProfile
} from "../../services/database";
import { throwError } from "../../services/error";
import { uploadProfilePicture } from "../../services/storage";
import {
  exponentialBackOff,
  getDistrictFromCode,
  getStateFromCode
} from "../../utils/constants";
import { isOnline, isValidObject } from "../../utils/validators";
import store from "../store/store";
import { authActions } from "./authSaga";
import { statusActions } from "./statusSaga";

export const actionTypes = {
  SET_ACCOUNT_PROFILE: "SET_ACCOUNT_PROFILE",
  CREATE_CLINIC_PROFILE: "CREATE_CLINIC_PROFILE",
  EDIT_PROFILE: "EDIT_PROFILE"
};

export const profileActions = {
  //create patient profile

  //linked and unlinked profile get actions
  setAccountProfile: (profile) => {
    store.dispatch({
      type: actionTypes.SET_ACCOUNT_PROFILE,
      payload: {
        data: profile
      }
    });
  },

  //create profile actions
  createClinicProfile: (data) => {
    store.dispatch({
      type: actionTypes.CREATE_CLINIC_PROFILE,
      payload: data
    });
  },

  // EDIT PROFILE
  editProfile: (data) => {
    store.dispatch({
      type: actionTypes.EDIT_PROFILE,
      payload: {
        data: data
      }
    });
  }
};

function* setProfilesWorker(action) {
  try {
    yield put({
      type: "SET_PROFILES",
      payload: action.payload.data
    });

    if (!isValidObject(action.payload.data)) {
      if (typeof store.getState().auth.data.displayName === "string") {
        yield createProfile(store.getState().auth.data.displayName);
        yield authActions.removeDisplayName();
      } else {
        authActions.logout();
      }
    }
  } catch (error) {
    statusActions.setErrorStatus(error);
  }
}

function* createClinicProfileWorker(action) {
  const profileId = Object.keys(store.getState().profile.data)[0];
  try {
    if (isOnline()) {
      if (
        store
          .getState()
          .connection.data.find(
            (data) =>
              data.brandId === action.payload.data.brandId &&
              data.userType !== "owner"
          )
      ) {
        throw throwError("custom", "Permission denied");
      }
      yield setProfileLoading(true);

      const modifyStateAndCityInData = {
        ...action.payload.data,
        state: getStateFromCode(action.payload.data.state),
        city: getDistrictFromCode(
          action.payload.data.state,
          action.payload.data.city
        )
      };

      const previousConnectionTotalData =
        store.getState().connection.data.length;

      if (action.payload.data.brandId) {
        const documentId = yield createClinicProfile(
          modifyStateAndCityInData,
          action.payload.data.brandId
        );

        yield addConnectionsToProfile(
          documentId,
          modifyStateAndCityInData,
          profileId,
          action.payload.data.brandId
        );
      } else {
        const brandId = yield createClinicBrand(
          modifyStateAndCityInData,
          profileId
        );
        if (brandId) {
          yield uploadProfilePicture(action.payload.data.companyLogo, brandId);
          const documentId = yield createClinicProfile(
            modifyStateAndCityInData,
            brandId
          );
          yield addConnectionsToProfile(
            documentId,
            modifyStateAndCityInData,
            profileId,
            brandId
          );
        }
      }
      statusActions.setSuccessStatus("Brand created successfully");

      yield exponentialBackOff(
        () => {
          if (
            previousConnectionTotalData <
            store.getState().connection.data.length
          ) {
            return true;
          } else {
            return false;
          }
        },
        1,
        4
      );
      yield action.payload.navigate("/");
      yield setProfileLoading(false);
    }
  } catch (error) {
    statusActions.setErrorStatus(error);
    yield setProfileLoading(false);
  }
}

// edit profile
export function* editProfileWorker(action) {
  try {
    if (isOnline()) {
      const profileId =
        store.getState().profile.data &&
        Object.keys(store.getState().profile.data)[0];

      let connectionIds = {};
      store.getState().connection?.data?.forEach((data) => {
        connectionIds = { [data.connectionId]: data.connectionId };
      });

      yield setProfileLoading(true);

      yield editProfile(
        action.payload.data,
        profileId,
        Object.keys(connectionIds)
      );

      yield setProfileLoading(false);
      statusActions.setSuccessStatus("Successfully saved");
    }
  } catch (error) {
    statusActions.setErrorStatus(error);
    yield setProfileLoading(false);
  }
}

export function* profileWatcher() {
  yield all([
    takeEvery("CREATE_CLINIC_PROFILE", createClinicProfileWorker),
    takeEvery("EDIT_PROFILE", editProfileWorker),
    takeEvery("SET_ACCOUNT_PROFILE", setProfilesWorker)
  ]);
}

function* setProfileLoading(bool) {
  yield put({
    type: "SET_PROFILE_LOADING",
    payload: {
      loading: bool
    }
  });
}
