export const UserIcon = (props) => {
  return (
    <svg
      width={props.width ? props.width : "16"}
      height={props.height ? props.height : "16"}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4172 8.41908C11.4772 7.66336 12.1685 6.42373 12.1685 5.02265C12.1685 2.72057 10.3023 0.85437 8.00024 0.85437C5.69816 0.85437 3.83196 2.72057 3.83196 5.02265C3.83196 6.42373 4.52323 7.66336 5.58331 8.41908C2.82629 9.40985 0.854614 12.0475 0.854614 15.1456H2.04555C2.04555 11.8569 4.71155 9.19093 8.00024 9.19093C11.2889 9.19093 13.9549 11.8569 13.9549 15.1456H15.1459C15.1459 12.0475 13.1742 9.40985 10.4172 8.41908ZM10.9776 5.02265C10.9776 6.66699 9.64458 7.99999 8.00024 7.99999C6.3559 7.99999 5.02289 6.66699 5.02289 5.02265C5.02289 3.37831 6.3559 2.04531 8.00024 2.04531C9.64458 2.04531 10.9776 3.37831 10.9776 5.02265Z"
        fill={props.color}
      />
    </svg>
  );
};

export const StatusIcon = (props) => {
  return (
    <svg
      width={props.width ? props.width : "16"}
      height={props.height ? props.height : "16"}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0617 6.3288C15.4293 6.23028 15.8104 6.44822 15.8769 6.82299C16.0198 7.62812 16.0386 8.45178 15.9315 9.26518C15.7944 10.3068 15.4535 11.3112 14.9282 12.221C14.4029 13.1308 13.7035 13.9283 12.8701 14.5678C12.2192 15.0672 11.4964 15.4628 10.7277 15.7416C10.3699 15.8714 9.99066 15.6503 9.89215 15.2827C9.79363 14.915 10.0136 14.5405 10.369 14.4043C10.9638 14.1764 11.5237 13.8635 12.031 13.4743C12.7208 12.9449 13.2997 12.2848 13.7345 11.5318C14.1692 10.7787 14.4514 9.94739 14.5649 9.08527C14.6484 8.4514 14.6394 7.81 14.5394 7.18097C14.4796 6.80506 14.694 6.42731 15.0617 6.3288Z"
        fill={props.color}
      />
      <path
        d="M2.83047 3.05149C2.56132 2.78234 2.55954 2.34339 2.85084 2.0984C3.47666 1.57208 4.18054 1.14393 4.93852 0.829972C5.90912 0.427934 6.94941 0.221008 7.99998 0.221008C9.05056 0.221008 10.0908 0.427934 11.0615 0.829972C11.8194 1.14393 12.5233 1.57208 13.1491 2.0984C13.4404 2.34339 13.4387 2.78234 13.1695 3.05149C12.9004 3.32064 12.466 3.31743 12.1704 3.0777C11.6756 2.67655 11.1246 2.3481 10.534 2.10344C9.73059 1.77067 8.86955 1.5994 7.99998 1.5994C7.13042 1.5994 6.26938 1.77067 5.466 2.10344C4.87533 2.3481 4.32434 2.67655 3.8296 3.0777C3.53395 3.31743 3.09961 3.32064 2.83047 3.05149Z"
        fill={props.color}
      />
      <path
        d="M6.10783 15.2827C6.00931 15.6503 5.63006 15.8714 5.27223 15.7416C4.50353 15.4628 3.7808 15.0673 3.12991 14.5678C2.29643 13.9283 1.59708 13.1308 1.0718 12.221C0.54651 11.3112 0.205569 10.3068 0.0684415 9.2652C-0.0386454 8.4518 -0.0197992 7.62814 0.123094 6.82301C0.189608 6.44824 0.570643 6.2303 0.938305 6.32882C1.30597 6.42733 1.52036 6.80508 1.46057 7.18099C1.36054 7.81002 1.35159 8.45141 1.43504 9.08529C1.54854 9.94741 1.83074 10.7787 2.26552 11.5318C2.7003 12.2849 3.27915 12.9449 3.96902 13.4743C4.47624 13.8635 5.03618 14.1764 5.63096 14.4043C5.9864 14.5405 6.20634 14.915 6.10783 15.2827Z"
        fill={props.color}
      />
    </svg>
  );
};

export const OptionIcon = (props) => {
  return (
    <svg
      width={props.width ? props.width : "24"}
      height={props.height ? props.height : "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="12"
        cy="18"
        r="1.5"
        transform="rotate(-90 12 18)"
        fill={props.color ? props.color : "#4E4E4E"}
      />
      <circle
        cx="12"
        cy="12"
        r="1.5"
        transform="rotate(-90 12 12)"
        fill={props.color ? props.color : "#4E4E4E"}
      />
      <circle
        cx="12"
        cy="6"
        r="1.5"
        transform="rotate(-90 12 6)"
        fill={props.color ? props.color : "#4E4E4E"}
      />
    </svg>
  );
};

export const BlockIcon = (props) => {
  return (
    <svg
      width={props.width ? props.width : "16"}
      height={props.height ? props.height : "16"}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8ZM13.8 8C13.8 11.2033 11.2033 13.8 8 13.8C6.61448 13.8 5.34242 13.3142 4.34489 12.5036L12.5036 4.34489C13.3142 5.34242 13.8 6.61448 13.8 8ZM3.49637 11.6551L11.6551 3.49637C10.6575 2.68581 9.3855 2.2 8 2.2C4.79675 2.2 2.2 4.79675 2.2 8C2.2 9.3855 2.68581 10.6575 3.49637 11.6551Z"
        fill={props.color ? props.color : "#4E4E4E"}
      />
    </svg>
  );
};

export const DeleteIcon = (props) => {
  return (
    <svg
      width={props.width ? props.width : "16"}
      height={props.height ? props.height : "16"}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.0038 2.00774C14.0038 1.87881 13.8962 1.77429 13.7636 1.77429H11.0016L10.1439 0.996094H5.85516L4.99741 1.77429H2.23547C2.10283 1.77429 1.9953 1.87881 1.9953 2.00774V2.68972C1.9953 2.81865 2.10283 2.92318 2.23547 2.92318H5.85516L6.68102 2.10783H9.36273L10.1439 2.92318H13.7636C13.8962 2.92318 14.0038 2.81865 14.0038 2.68972V2.00774Z"
        fill={props.color ? props.color : "#4E4E4E"}
      />
      <path
        d="M5.95504 6.5001C5.94954 6.36758 6.05857 6.25723 6.19501 6.25723H6.92088C7.05352 6.25723 7.16105 6.36175 7.16105 6.49069V12.5325C7.16105 12.6615 7.05352 12.766 6.92088 12.766H6.44578C6.3169 12.766 6.211 12.6671 6.20581 12.542L5.95504 6.5001Z"
        fill={props.color ? props.color : "#4E4E4E"}
      />
      <path
        d="M9.80405 6.25723C9.94049 6.25723 10.0495 6.36758 10.044 6.5001L9.79325 12.542C9.78806 12.6671 9.68215 12.766 9.55328 12.766H9.07818C8.94554 12.766 8.83801 12.6615 8.83801 12.5325V6.49069C8.83801 6.36175 8.94554 6.25723 9.07818 6.25723H9.80405Z"
        fill={props.color ? props.color : "#4E4E4E"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.28192 13.4472C3.28192 14.3032 4.05389 15.0036 4.99741 15.0036H11.0016C11.9452 15.0036 12.7171 14.3032 12.7171 13.4472L13.1351 4.13895C13.141 4.00614 13.0319 3.89531 12.8951 3.89531H3.10393C2.96718 3.89531 2.85802 4.00614 2.86399 4.13895L3.28192 13.4472ZM4.28434 12.724C4.28434 13.4272 4.89214 14.0025 5.635 14.0025H10.3623C11.1052 14.0025 11.713 13.4272 11.713 12.724L12.0506 5.05271H3.94667L4.28434 12.724Z"
        fill={props.color ? props.color : "#4E4E4E"}
      />
    </svg>
  );
};

export const RequestIcon = (props) => {
  return (
    <svg
      width={props.width ? props.width : "16"}
      height={props.height ? props.height : "16"}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.55321 2.75C1.24802 2.75 1.00061 3.00184 1.00061 3.3125C1.00061 3.62316 1.24802 3.87499 1.55321 3.87499L14.4472 3.87499C14.7524 3.87499 14.9998 3.62316 14.9998 3.3125C14.9998 3.00184 14.7524 2.75 14.4472 2.75L1.55321 2.75Z"
        fill={props.color ? props.color : "#4E4E4E"}
      />
      <path
        d="M6.15783 7.43746C5.85264 7.43746 5.60523 7.6893 5.60523 7.99996C5.60523 8.31062 5.85264 8.56246 6.15783 8.56246L14.4468 8.56246C14.752 8.56246 14.9994 8.31062 14.9994 7.99996C14.9994 7.6893 14.752 7.43746 14.4468 7.43746L6.15783 7.43746Z"
        fill={props.color ? props.color : "#4E4E4E"}
      />
      <path
        d="M1.55321 12.1249C1.24802 12.1249 1.00061 12.3768 1.00061 12.6874C1.00061 12.9981 1.24802 13.2499 1.55321 13.2499L14.4472 13.2499C14.7524 13.2499 14.9998 12.9981 14.9998 12.6874C14.9998 12.3768 14.7524 12.1249 14.4472 12.1249L1.55321 12.1249Z"
        fill={props.color ? props.color : "#4E4E4E"}
      />
      <path
        d="M1.00061 7.99996C1.00061 7.6893 1.24802 7.43746 1.55321 7.43746H3.39521C3.70041 7.43746 3.94781 7.6893 3.94781 7.99996C3.94781 8.31062 3.70041 8.56246 3.39521 8.56246H1.55321C1.24802 8.56246 1.00061 8.31062 1.00061 7.99996Z"
        fill={props.color ? props.color : "#4E4E4E"}
      />
    </svg>
  );
};

export const AddIcon = (props) => {
  return (
    <svg
      width={props.width ? props.width : "16"}
      height={props.height ? props.height : "16"}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.408551 7.33333C0.182914 7.33333 2.15235e-08 7.51625 2.15235e-08 7.74188L0 8.25812C0 8.48375 0.182914 8.66667 0.408551 8.66667H7.33333L7.33333 15.5915C7.33333 15.8171 7.51625 16 7.74188 16H8.25812C8.48375 16 8.66667 15.8171 8.66667 15.5915V8.66667H15.5915C15.8171 8.66667 16 8.48375 16 8.25812V7.74188C16 7.51625 15.8171 7.33333 15.5915 7.33333L8.66667 7.33333V0.408551C8.66667 0.182914 8.48375 5.38098e-08 8.25812 1.0762e-08L7.74188 0C7.51625 -4.30478e-08 7.33333 0.182914 7.33333 0.40855L7.33333 7.33333L0.408551 7.33333Z"
        fill={props.color ? props.color : "#4E4E4E"}
      />
    </svg>
  );
};

export const ArrowIcon = (props) => {
  return (
    <svg
      width={props.width ? props.width : "16"}
      height={props.height ? props.height : "16"}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.96628 3.06018C5.88309 2.97994 5.74819 2.97994 5.66499 3.06018L5.0624 3.64134C4.9792 3.72159 4.9792 3.85168 5.0624 3.93192L9.12988 7.85475C9.21308 7.935 9.21308 8.06509 9.12988 8.14533L5.06245 12.0681C4.97925 12.1484 4.97925 12.2785 5.06245 12.3587L5.66499 12.9398C5.74819 13.0201 5.88309 13.0201 5.96628 12.9398L10.9376 8.14529C11.0208 8.06505 11.0208 7.93495 10.9376 7.85471L5.96628 3.06018Z"
        fill={props.color ? props.color : "#4E4E4E"}
      />
    </svg>
  );
};

export const TickIcon = (props) => {
  return (
    <svg
      width={props.width ? props.width : "16"}
      height={props.height ? props.height : "16"}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.22275 11.5C6.96616 11.7478 6.55014 11.7478 6.29355 11.5L3.19238 8.5C2.93579 8.25218 2.93579 7.85038 3.19239 7.60256C3.44898 7.35473 3.865 7.35473 4.12159 7.60256L6.29357 9.7051C6.55016 9.95292 6.96618 9.95292 7.22277 9.7051L11.455 5.18587C11.7116 4.93805 12.1277 4.93804 12.3843 5.18587C12.6408 5.43369 12.6408 5.83549 12.3843 6.08331L7.22275 11.5Z"
        fill={props.color ? props.color : "#4E4E4E"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM14.8 8C14.8 11.7555 11.7555 14.8 8 14.8C4.24446 14.8 1.2 11.7555 1.2 8C1.2 4.24446 4.24446 1.2 8 1.2C11.7555 1.2 14.8 4.24446 14.8 8Z"
        fill={props.color ? props.color : "#4E4E4E"}
      />
    </svg>
  );
};

export const MoreIcon = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="4"
        cy="8"
        r="1"
        fill={props.color ? props.color : "#4E4E4E"}
      />
      <circle
        cx="8"
        cy="8"
        r="1"
        fill={props.color ? props.color : "#4E4E4E"}
      />
      <circle
        cx="12"
        cy="8"
        r="1"
        fill={props.color ? props.color : "#4E4E4E"}
      />
    </svg>
  );
};
