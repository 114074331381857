import { api } from "../utils/constants";

//check phone number availability
export async function checkAccountAvailability(phoneNumber) {
  const response = await fetch(
    `${api.baseUrl}/accountExists?phoneNumber=${encodeURIComponent(
      phoneNumber
    )}&type=clinicStaff`,
    {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    }
  );
  const result = await response.json();
  if (result.error) {
    throw result.error;
  }
  return result;
}

export async function getStaffs(entityId, accessToken) {
  const response = await fetch(`${api.baseUrl}/staffManagement`, {
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`
    },
    body: JSON.stringify({
      entityData: {
        id: entityId,
        type: "clinic"
      },
      method: "get"
    })
  });
  const result = await response.json();
  if (result.error) {
    throw result.error;
  }
  return result;
}

export async function addStaff(
  data = {
    entityData: {
      id: "",
      type: "clinic",
      brandId: "",
      name: ""
    },
    staffData: {
      phoneNumber: "",
      type: ""
    },
    method: "add"
  },
  accessToken
) {
  const response = await fetch(`${api.baseUrl}/staffManagement`, {
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`
    },
    body: JSON.stringify(data)
  });
  const result = await response.json();
  if (result.error) {
    throw result.error;
  }
  return result;
}

export async function removeStaff(entityId, phoneNumber, accessToken) {
  const response = await fetch(`${api.baseUrl}/staffManagement`, {
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`
    },
    body: JSON.stringify({
      entityData: {
        id: entityId,
        type: "clinic"
      },
      staffData: {
        phoneNumber: phoneNumber
      },
      method: "remove"
    })
  });
  const result = await response.json();
  if (result.error) {
    throw result.error;
  }
  return result;
}

export async function uploadDocument(data) {
  const response = await fetch(`${api.baseUrl}/uploadDocument`, {
    method: "post",
    body: data
  });

  const result = await response.json();
  if (result.error) {
    throw result.error;
  }
  return result;
}

export async function deleteDocument(documentId) {
  const response = await fetch(`${api.baseUrl}/uploadDocument`, {
    method: "delete",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json"
    },
    body: JSON.stringify({ documentId: documentId })
  });

  const result = await response.json();
  if (result.error) {
    throw result.error;
  }
  return result;
}
