import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Button from "../../front-end-global-components/components/Button/Button";
import InputBox from "../../front-end-global-components/components/InputBox/InputBox";
import Header from "../../front-end-global-components/components/Header/Header";
import RoundedProfilePicture from "../../front-end-global-components/components/RoundedProfilePicture/RoundedProfilePicture";
import { profileActions } from "../../redux/sagas/profileSaga";
import ProfileCards from "../../components/ProfileCards/ProfileCards";
import { isValidArray, isValidObject } from "../../utils/validators";
import ConditionalRender from "../../front-end-global-components/components/ConditionalRender/ConditionalRender";
import NativeSelect from "../../front-end-global-components/components/NativeSelect/NativeSelect";
import {
  stateCodeWithDistricts,
  stateOptionsWithCode
} from "../../utils/constants";
import CropperModal from "../../front-end-global-components/components/CropperModal/CropperModal";

function CreateClinic(props) {
  const [clinicCreateFromData, setClinicCreateFromData] = useState({
    companyName: "",
    website: "",
    companyLogo: "",
    locality: "",
    email: "",
    address: "",
    city: "",
    state: "",
    pincode: "",
    phoneNumber: "+91"
  });
  const [selectedBrands, setSelectedBrands] = useState(null);
  const [step, setStep] = useState("brandSelection");
  const [file, setFile] = useState("");
  const [tempSrc, setTempSrc] = useState(null);

  useEffect(() => {
    if (isValidObject(selectedBrands)) {
      setClinicCreateFromData({
        ...clinicCreateFromData,
        companyName:
          props.connection?.brands &&
          props.connection?.brands[selectedBrands.documentId]?.name,
        website:
          props.connection?.brands &&
          props.connection?.brands[selectedBrands.documentId]?.website,
        email:
          props.connection?.brands &&
          props.connection?.brands[selectedBrands.documentId]?.email,
        companyLogo: selectedBrands?.documentId,
        brandId: selectedBrands?.documentId
      });
    }
    // eslint-disable-next-line
  }, [selectedBrands]);

  useEffect(() => {
    if (!isValidArray(props.connection.data)) {
      setStep("brandSubmission");
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="inherit-parent-height flex-place-children-page-center ">
        <div className="inherit-parent-height inherit-parent-width large-screen-bg-color large-screen-height large-screen-width large-screen-border">
          <ConditionalRender condition={file && file !== null}>
            <CropperModal
              aspectRatio={1}
              OnBlobCreate={(blob) => {
                const imageFile = new File([blob], `${+new Date()}.png`, {
                  type: "image/png"
                });
                setClinicCreateFromData({
                  ...clinicCreateFromData,
                  companyLogo: imageFile
                });
                setTempSrc(URL.createObjectURL(imageFile));
              }}
              file={file}
              setFile={() => {
                setFile("");
              }}
            />
          </ConditionalRender>
          <ConditionalRender condition={!file}>
            <>
              <Header
                removeResponsive={true}
                title="Add new clinic"
                backButtonOnClick={() => {
                  if (step === "brandSelection") {
                    props.navigate("/");
                  } else if (
                    step === "brandSubmission" &&
                    isValidArray(props.connection.data)
                  ) {
                    setStep("brandSelection");
                  } else if (step === "clinicCreation") {
                    setStep("brandSubmission");
                  }
                  setSelectedBrands(null);
                }}
                hideBackButton={false}
                supportIconOnClick={() => {}}
              />
              <ConditionalRender condition={step === "brandSelection"}>
                <BrandSelectionForm
                  addOnClick={() => {
                    setStep("brandSubmission");
                  }}
                  onCardClick={(data) => {
                    setSelectedBrands(data);
                    setStep("brandSubmission");
                  }}
                  connection={props.connection}
                />
              </ConditionalRender>

              <ConditionalRender condition={step === "brandSubmission"}>
                <BrandSubmissionForm
                  onChange={(event) => {
                    setClinicCreateFromData({
                      ...clinicCreateFromData,
                      [event.target.name]: event.target.value
                    });
                  }}
                  connection={props.connection}
                  selectedBrands={selectedBrands}
                  onSubmit={(event) => {
                    event.preventDefault();
                    setStep("clinicCreation");
                  }}
                  onProfilePictureSave={(file) => {
                    setClinicCreateFromData({
                      ...clinicCreateFromData,
                      companyLogo: file
                    });
                  }}
                  src={tempSrc}
                  setProfileImageSrc={setFile}
                  formData={clinicCreateFromData}
                />
              </ConditionalRender>

              <ConditionalRender condition={step === "clinicCreation"}>
                <ClinicCreationForm
                  onChange={(event) => {
                    setClinicCreateFromData({
                      ...clinicCreateFromData,
                      [event.target.name]: event.target.value
                    });
                  }}
                  onSubmit={(event) => {
                    event.preventDefault();

                    props.createClinicProfile({
                      data: clinicCreateFromData,
                      navigate: props.navigate
                    });
                  }}
                  profile={props.profile}
                  formData={clinicCreateFromData}
                />
              </ConditionalRender>
            </>
          </ConditionalRender>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = function (state) {
  return {
    profile: state.profile,
    connection: state.connection
  };
};

const mapDispatchToProps = function () {
  return {
    createClinicProfile: (data) => profileActions.createClinicProfile(data)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateClinic);

const BrandSelectionForm = (props) => {
  return (
    <div className="padding-larger overflow-y-scroll hide-scrollbar">
      <div className="text-align-center padding-bottom-larger">
        Use the existing public details of your company to create another
        branch/locality for Add new company by entering new information
      </div>
      {props?.connection?.brands &&
        Object.values(props.connection.brands).map(
          (data, index) =>
            props.connection.currentConnection?.userType === "owner" && (
              <ProfileCards
                connection={props.connection}
                key={index}
                data={data}
                onCardClick={() => props.onCardClick(data)}
              />
            )
        )}
      <ProfileCards
        type="add"
        text="Add Clinic"
        addOnClick={props.addOnClick}
      />
    </div>
  );
};

const BrandSubmissionForm = (props) => {
  const [isValidEmail, setIsValidEmail] = useState(true);

  return (
    <form
      className="remaining-body-height grey-abstract-background-image flex-center-children-vertically flex-direction-column overflow-y-scroll hide-scrollbar"
      onChange={props.onChange}
      onSubmit={props.onSubmit}
    >
      <div className="padding-right-larger padding-left-larger padding-top-larger inherit-parent-width max-width-500px flex-grow">
        <h1 className="font-weight-normal font-family-gilroy-medium font-size-medium font-color-secondary inherit-parent-width text-align-center">
          {props.connection.brands &&
          props.connection.brands[props.selectedBrands?.documentId]
            ? "VERIFY BASIC INFORMATION"
            : "CLINIC BASIC INFORMATION"}
        </h1>
        <div className=" overflow-y-auto hidden-scroll">
          <div className=" flex-center-children-horizontally margin-top-larger">
            <RoundedProfilePicture
              data-cy="brand-logo"
              userType="corporate"
              size="large"
              editMode={
                props.connection.brands &&
                props.connection.brands[props.selectedBrands?.documentId]
                  ? false
                  : true
              }
              src={
                props.connection.brands &&
                props.connection.brands[props.selectedBrands?.documentId]
                  ?.downloadURL
                  ? props.connection?.brands[props.selectedBrands?.documentId]
                      ?.downloadURL
                  : props.src
                  ? props.src
                  : ""
              }
              removeResponsive={true}
              setFile={props.setProfileImageSrc}
              newProfilePictureName={"profilePicture"}
              onProfilePictureSave={props.onProfilePictureSave}
            />
          </div>
          <div className="">
            <InputBox
              className="inherit-parent-width border-radius-default margin-vertical-large"
              name="companyName"
              removeResponsive={true}
              label="Clinic Name"
              value={props.formData.companyName}
              type="text"
              required
              disabled={
                props.connection.brands &&
                props.connection.brands[props.selectedBrands?.documentId]
                  ? true
                  : false
              }
              data-cy="company-name-field"
            />
            <InputBox
              className="inherit-parent-width border-radius-default margin-vertical-large"
              name="email"
              label="Clinic Email"
              removeResponsive={true}
              value={props.formData.email}
              type="email"
              required
              disabled={
                props.connection.brands &&
                props.connection.brands[props.selectedBrands?.documentId]
                  ? true
                  : false
              }
              isValidInput={(data) => {
                setIsValidEmail(data);
              }}
              data-cy="email-field"
            />
            <InputBox
              className="inherit-parent-width border-radius-default margin-vertical-large"
              name="website"
              label="Clinic Website"
              removeResponsive={true}
              value={props.formData.website}
              type="text"
              required
              disabled={
                props.connection.brands &&
                props.connection.brands[props.selectedBrands?.documentId]
                  ? true
                  : false
              }
              data-cy="website-field"
            />
          </div>
        </div>
      </div>
      <footer className="padding-left-larger padding-right-larger padding-bottom-larger padding-top-larger inherit-parent-width max-width-500px">
        <Button
          data-cy="verify-or-continue-button"
          type="submit"
          text="Continue"
          boxShadow={false}
          disabled={
            !(
              props.formData.companyName &&
              props.formData.email &&
              props.formData.website &&
              props.formData.companyLogo &&
              isValidEmail
            )
          }
        />
      </footer>
    </form>
  );
};

const ClinicCreationForm = (props) => {
  const [isValidPincode, setIsValidPincode] = useState(true);
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);
  return (
    <form
      className="remaining-body-height grey-abstract-background-image flex-center-children-vertically flex-direction-column overflow-y-scroll hide-scrollbar"
      onChange={props.onChange}
      onSubmit={props.onSubmit}
    >
      <div className="padding-right-larger padding-left-larger padding-top-larger inherit-parent-width max-width-500px flex-grow">
        <h1 className="font-weight-normal font-family-gilroy-medium font-size-medium font-color-secondary inherit-parent-width text-align-center">
          CLINIC BRANCH INFO
        </h1>
        <div className="">
          <InputBox
            className="inherit-parent-width border-radius-default margin-vertical-large"
            name="phoneNumber"
            removeResponsive={true}
            label="Branch Contact Number"
            value={props.formData.phoneNumber}
            type="text"
            required
            data-cy="branch-contact-number-field"
            isValidInput={(data) => {
              setIsValidPhoneNumber(data);
            }}
          />
          {/* <InputBox
              className="inherit-parent-width border-radius-default margin-vertical-large"
              name="email"
              removeResponsive={true}
              label="Branch Email"
              value={props.formData.email}
              type="text"
              required
              data-cy="company-name-field"
            /> */}
          <InputBox
            className="inherit-parent-width border-radius-default margin-vertical-large"
            name="address"
            removeResponsive={true}
            label="Branch Address"
            value={props.formData.address}
            type="text"
            required
            data-cy="branch-address-field"
          />
          <div className=" display-flex">
            <InputBox
              className="inherit-parent-width padding-right-default border-radius-default margin-vertical-large"
              name="locality"
              size="half"
              removeResponsive={true}
              label="Branch locality"
              value={props.formData.locality}
              type="text"
              required
              data-cy="branch-locality-field"
              isValidInput={(data) => {
                setIsValidPhoneNumber(data);
              }}
            />
            <InputBox
              className="inherit-parent-width padding-left-default border-radius-default margin-vertical-large"
              name="pincode"
              removeResponsive={true}
              size="half"
              label="Pincode"
              value={props.formData.pincode}
              type="text"
              required
              isValidInput={(data) => {
                setIsValidPincode(data);
              }}
              data-cy="branch-pincode-field"
            />
          </div>
          <div className=" display-flex">
            <NativeSelect
              labelClassName="letter-spacing-4-percentage"
              className="padding-right-default"
              selectClassName="text-transform-capitalize padding-top-large"
              optionsClassName="text-transform-capitalize"
              name="state"
              defaultValue={props.formData.state}
              label="State"
              required
              size="half"
              data-cy="branch-state-field"
              options={stateOptionsWithCode}
            />
            <NativeSelect
              labelClassName="letter-spacing-4-percentage"
              className="padding-left-default"
              selectClassName="text-transform-capitalize padding-top-large"
              optionsClassName="text-transform-capitalize"
              name="city"
              label="city"
              required
              size="half"
              data-cy="branch-city-field"
              defaultValue={props.formData.city}
              options={stateCodeWithDistricts[props.formData.state]}
            />
          </div>
        </div>
      </div>
      <footer className="padding-left-larger padding-right-larger padding-bottom-larger padding-top-larger inherit-parent-width max-width-500px">
        <Button
          data-cy="Continue-button-field"
          loading={props.profile.loading}
          type="submit"
          text="Continue"
          boxShadow={false}
          disabled={
            !(
              props.formData.companyName &&
              props.formData.website &&
              props.formData.companyLogo &&
              props.formData.locality &&
              props.formData.email &&
              props.formData.address &&
              props.formData.city &&
              props.formData.state &&
              props.formData.pincode &&
              props.formData.phoneNumber &&
              props.formData.phoneNumber.length > 3 &&
              isValidPincode &&
              isValidPhoneNumber
            )
          }
        />
      </footer>
    </form>
  );
};
