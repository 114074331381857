import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { authActions } from "../../redux/sagas/authSaga";
import Button from "../../front-end-global-components/components/Button/Button";
import OTPInputBox from "../../front-end-global-components/components/OTPInputBox/OTPInputBox";
import InputBox from "../../front-end-global-components/components/InputBox/InputBox";
import { reCaptchaVerifier } from "../../services/authentication";
import Header from "../../front-end-global-components/components/Header/Header";
import ConditionalRender from "../../front-end-global-components/components/ConditionalRender/ConditionalRender";
import { mockActions } from "../../redux/sagas/mockSaga";

function Login(props) {
  const [loginFormData, setLoginFormData] = useState({
    phoneNumber: "+91",
    OTP: null
  });

  //invisible recaptcha for authentication
  useEffect(() => {
    window.reCaptchaVerifier = reCaptchaVerifier();
  }, []);

  const setOTPValue = (otp) => {
    if (loginFormData.phoneNumber !== null) {
      setLoginFormData({
        ...loginFormData,
        OTP: otp
      });
    }
  };

  const loginFormChange = (event) => {
    //To clear auth verification while user trying to change phone number after otp sent
    if (
      event.target.name === "phoneNumber" &&
      props.auth.credentials.verificationId &&
      loginFormData.phoneNumber !== event.target.value
    ) {
      props.clearCredentials();
    }
    if (event.target.name.includes("otp")) {
      return;
    } else {
      setLoginFormData({
        ...loginFormData,
        [event.target.name]: event.target.value
      });
    }
  };

  const otpRequestHandler = (event) => {
    event.preventDefault();
    props.sendOtp(loginFormData.phoneNumber, "login");
  };

  const otpVerifyHandler = (event) => {
    event.preventDefault();
    if (process.env.REACT_APP_STAGING === "local") {
      props.mockAuthVerifyOtp("login");
    } else {
      props.verifyOtp(loginFormData.OTP);
    }
  };

  const isOtpButtonDisabled = () => {
    if (
      typeof loginFormData.phoneNumber === "string" &&
      loginFormData.phoneNumber.trim().length >= 13
    ) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div className="inherit-parent-height flex-place-children-page-center ">
      <div className="inherit-parent-height inherit-parent-width large-screen-bg-color large-screen-height large-screen-width large-screen-border">
        <Header
          removeResponsive={true}
          userType="corporate"
          backButtonOnClick={() => {}}
          hideBackButton
          maxStepperCount={3}
          currentStepperValue={0}
          supportIconOnClick={() => {}}
        />
        <article className="remaining-body-height inherit-parent-width large-screen-padding">
          <form
            onChange={(event) => {
              loginFormChange(event);
            }}
            onSubmit={(event) => event.preventDefault()}
            className="inherit-parent-height overflow-x-scroll hide-scrollbar width-100-percent grey-abstract-background-image flex-center-children-vertically flex-direction-column flex-justify-content-space-between"
          >
            <main className="padding-larger inherit-parent-width max-width-500px">
              <h1 className="font-weight-normal font-family-gilroy-medium font-size-small font-color-secondary inherit-parent-width text-align-center">
                CLINIC LOGIN
              </h1>
              <InputBox
                className="inherit-parent-width border-radius-default margin-vertical-large"
                name="phoneNumber"
                label="Phone Number"
                type="tel"
                value={loginFormData.phoneNumber}
                removeResponsive={true}
                buttonTileId="login-button"
                buttonTile={
                  props.auth.credentials.verificationId !== null
                    ? "Resend OTP"
                    : "Get OTP"
                }
                buttonType={
                  typeof props.auth.credentials.verificationId === "string"
                    ? "button"
                    : "submit"
                }
                onButtonClick={(event) => {
                  otpRequestHandler(event);
                }}
                data-cy="login-screen-phone-number-field"
                required
                suffixButtonDisabled={isOtpButtonDisabled()}
              />
              <ConditionalRender
                condition={
                  typeof props.auth.credentials.verificationId === "string"
                }
              >
                <OTPInputBox
                  name="otp"
                  setValue={setOTPValue}
                  className="margin-bottom-large"
                  required
                />
              </ConditionalRender>
            </main>
            <footer className="padding-larger inherit-parent-width max-width-500px ">
              <Button
                data-cy={
                  props.auth.loading === true
                    ? "login-loading-button"
                    : "login-button"
                }
                autofocus={true}
                loading={props.auth.loading}
                type={
                  typeof props.auth.credentials.verificationId === "string"
                    ? "submit"
                    : "button"
                }
                className="margin-bottom-large"
                text="Login"
                onClick={(event) => {
                  otpVerifyHandler(event);
                }}
                disabled={
                  !(
                    typeof loginFormData?.OTP === "string" &&
                    loginFormData?.OTP.length === 6
                  )
                }
                boxShadow={false}
              />
              <h2 className="font-weight-normal font-family-gilroy-medium font-size-small font-color-secondary inherit-parent-width text-align-center">
                Want to register your clinic?
                <span
                  data-cy="signup-page-link"
                  className={`margin-left-default font-color-primary ${
                    props.auth.loading === true
                      ? "pointer-events-none"
                      : " cursor-pointer"
                  }`}
                  onClick={() => {
                    props.navigate("/signup");
                    props.clearCredentials();
                  }}
                >
                  Signup
                </span>
              </h2>
            </footer>
          </form>
        </article>
        <div id="recaptcha-placeholder"></div>
      </div>
    </div>
  );
}

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    profile: state.profile
  };
};

const mapDispatchToProps = function () {
  return {
    verifyOtp: (loginOtp) => authActions.verifyOtp(loginOtp),
    sendOtp: (phoneNumber, type) => authActions.sendOtp(phoneNumber, type),
    mockAuthVerifyOtp: (type) => mockActions.mockAuthVerifyOtp(type),
    clearCredentials: () => authActions.clearCredentials()
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
