import store from "../store/store";
import { put, takeEvery, all } from "redux-saga/effects";
import { statusActions } from "./statusSaga";
import { bucketNames, getFileUrl } from "../../services/storage";
import {
  getBrandDataById,
  getCorporatePackages,
  updateCorporatePackage
} from "../../services/database";
import { isOnline, isValidObject } from "../../utils/validators";
import { addStaff, getStaffs, removeStaff } from "../../services/api";
import { throwError } from "../../services/error";

export const actionTypes = {
  SET_CONNECTIONS: "SET_CONNECTIONS",
  SET_CURRENT_CONNECTION: "SET_CURRENT_CONNECTION",
  GET_BRAND_DATA: "GET_BRAND_DATA",
  GET_BRAND_LOGO: "GET_BRAND_LOGO",
  GET_CONNECTED_STAFFS: "GET_CONNECTED_STAFFS",
  CREATE_NEW_STAFF: "CREATE_NEW_STAFF",
  REMOVE_STAFF: "REMOVE_STAFF",
  GET_CORPORATE_PACKAGES: "GET_CORPORATE_PACKAGES",
  UPDATE_CORPORATE_PACKAGE: "UPDATE_CORPORATE_PACKAGE"
};

export const connectionActions = {
  setConnections: (data) => {
    store.dispatch({
      type: actionTypes.SET_CONNECTIONS,
      payload: {
        data: data
      }
    });
  },

  setCurrentConnection: (data) => {
    store.dispatch({
      type: actionTypes.SET_CURRENT_CONNECTION,
      payload: {
        data: data
      }
    });
  },

  getConnectedStaffs: (data) => {
    store.dispatch({
      type: actionTypes.GET_CONNECTED_STAFFS,
      payload: {
        data: data
      }
    });
  },

  getBrandDownloadableUrl: (data) => {
    store.dispatch({
      type: actionTypes.GET_BRAND_LOGO,
      payload: {
        data: data
      }
    });
  },

  getCorporatePackages: (data) => {
    store.dispatch({
      type: actionTypes.GET_CORPORATE_PACKAGES,
      payload: {
        data: data
      }
    });
  },

  modifyCorporatePackages: (data) => {
    store.dispatch({
      type: actionTypes.UPDATE_CORPORATE_PACKAGE,
      payload: {
        data: data
      }
    });
  },

  createNewStaff: (data) => {
    store.dispatch({
      type: actionTypes.CREATE_NEW_STAFF,
      payload: {
        data: data
      }
    });
  },

  removeStaff: (data) => {
    store.dispatch({
      type: actionTypes.REMOVE_STAFF,
      payload: {
        data: data
      }
    });
  },
  getBrandData: (data) => {
    store.dispatch({
      type: actionTypes.GET_BRAND_DATA,
      payload: {
        data: data
      }
    });
  }
};

function* setConnectionsWorker(action) {
  try {
    yield setConnectionsLoading(true);
    yield put({
      type: "SET_CONNECTIONS_DATA",
      payload: {
        data: action.payload.data
      }
    });
    yield setConnectionsLoading(false);
  } catch (error) {
    yield statusActions.setErrorStatus(error);
    yield setConnectionsLoading(false);
  }
}

function* setCurrentConnectionWorker(action) {
  try {
    yield setConnectionsLoading(true);
    yield put({
      type: "SET_CURRENT_CONNECTION_DATA",
      payload: {
        data: action.payload.data
      }
    });
    yield setConnectionsLoading(false);
  } catch (error) {
    yield statusActions.setErrorStatus(error);
    yield setConnectionsLoading(false);
  }
}

function* getConnectedStaffWorker(action) {
  try {
    if (isOnline()) {
      if (store.getState().connection.currentConnection.userType === "staff") {
        throw throwError("custom", "Permission denied");
      }
      yield setConnectionsLoading(true);
      const response = yield getStaffs(
        action.payload.data.branchId,
        store.getState().auth.data.accessToken
      );
      let staffs = {};
      response.result.forEach((staff) => {
        staffs[staff.profileId] = staff;
      });
      yield put({
        type: "SET_CONNECTED_STAFF_DATA",
        payload: {
          data: staffs
        }
      });
      yield setConnectionsLoading(false);
    }
  } catch (error) {
    yield statusActions.setErrorStatus(error);
    yield setConnectionsLoading(false);
  }
}

function* createNewStaffWorker(action) {
  try {
    if (isOnline()) {
      if (
        store.getState().connection.currentConnection.userType === "staff" ||
        (store.getState().connection.currentConnection.userType === "manager" &&
          action.payload.data.userType === "manager")
      ) {
        throw throwError("custom", "Permission denied");
      }

      if (
        action.payload.data.phoneNumber ===
          store.getState().auth.data.phoneNumber ||
        Object.values(store.getState().connection.connectedStaffs).some(
          (element) => element.phoneNumber === action.payload.data.phoneNumber
        )
      ) {
        throw throwError("custom", "Phone number already exist");
      }

      yield setConnectionsLoading(true);
      const branchData = store
        .getState()
        .connection.data.find(
          (connection) =>
            connection.connectionId === action.payload.data.branchId
        );
      const result = yield addStaff(
        {
          entityData: {
            id: action.payload.data.branchId,
            brandId: branchData.brandId,
            name: branchData.companyName,
            type: "clinic"
          },
          staffData: {
            type: action.payload.data.userType,
            phoneNumber: action.payload.data.phoneNumber
          },
          method: "add"
        },
        store.getState().auth.data.accessToken
      );
      yield connectionActions.getConnectedStaffs({
        branchId: action.payload.data.branchId
      });
      if (result.success === true) {
        statusActions.setSuccessStatus(
          `${
            action.payload.data.userType.charAt(0).toUpperCase() +
            action.payload.data.userType.slice(1)
          }
         added successfully`
        );
      }
      yield setConnectionsLoading(false);
    }
  } catch (error) {
    yield statusActions.setErrorStatus(error);
    yield setConnectionsLoading(false);
  }
}

function* removeStaffWorker(action) {
  try {
    if (isOnline()) {
      if (
        store.getState().connection.currentConnection.userType === "staff" ||
        (store.getState().connection.currentConnection.userType === "manager" &&
          action.payload.data.userType === "manager")
      ) {
        throw throwError("custom", "Permission denied");
      }
      yield setConnectionsLoading(true);
      const result = yield removeStaff(
        action.payload.data.branchId,
        action.payload.data.phoneNumber,
        store.getState().auth.data.accessToken
      );
      connectionActions.getConnectedStaffs({
        branchId: action.payload.data.branchId
      });
      if (result.success === true) {
        statusActions.setSuccessStatus(`Removed successfully`);
      }
      yield setConnectionsLoading(false);
    }
  } catch (error) {
    yield statusActions.setErrorStatus(error);
    yield setConnectionsLoading(false);
  }
}

function* getBrandDataWorker(action) {
  try {
    if (isOnline()) {
      const brandIds = action.payload.data.filter(
        (item, index) => action.payload.data.indexOf(item) === index
      );

      for (let data of Object.values(brandIds)) {
        let brandData = yield getBrandDataById(data);
        connectionActions.getBrandDownloadableUrl({
          ...brandData,
          documentId: data
        });
      }
    }
  } catch (error) {
    yield statusActions.setErrorStatus(error);
  }
}

function* getBrandLogoWorker(action) {
  try {
    if (isOnline()) {
      const data = action.payload.data;
      if (
        store.getState().connection.brands &&
        isValidObject(store.getState().connection.brands) &&
        Object.keys(store.getState().connection.brands).includes(
          data.documentId
        ) &&
        store.getState().connection.brands[data.documentId].downloadURL
      ) {
        return;
      }

      let URL;

      URL = yield getFileUrl(
        `gs://${bucketNames.nintoProfilePictures}/clinicBrands/${data.documentId}.png`
      );

      yield put({
        type: "SET_CONNECTIONS_BRAND_DATA",
        payload: {
          data: {
            [data.documentId]: {
              ...data,
              documentId: data.documentId,
              ...(typeof URL === "string" ? { downloadURL: URL } : {})
            }
          }
        }
      });
    }
  } catch (error) {
    if (error.toString().includes("does not exist")) {
      const data = action.payload.data;
      yield put({
        type: "SET_CONNECTIONS_BRAND_DATA",
        payload: {
          data: {
            [data.documentId]: {
              ...data,
              documentId: data.documentId
            }
          }
        }
      });
    } else {
      yield statusActions.setErrorStatus(error);
    }
  }
  // yield setConnectionsLoading(false);
}

function* getPartneredCorporates(action) {
  try {
    if (isOnline()) {
      yield setConnectionsLoading(true);

      const brandData = yield getCorporatePackages(action.payload.data);
      yield put({
        type: "SET_CORPORATE_PACKAGES",
        payload: {
          data: brandData
        }
      });

      let corporateBrandData = {};

      for (let data of Object.values(brandData)) {
        corporateBrandData = {
          ...corporateBrandData,
          [data.corporateBrandId]: {
            downloadURL: yield getFileUrl(
              `gs://${bucketNames.nintoProfilePictures}/corporateBrands/${data.corporateBrandId}.png`
            )
          }
        };
      }

      yield put({
        type: "SET_CORPORATE_BRANDS_LIST",
        payload: {
          data: corporateBrandData
        }
      });

      yield setConnectionsLoading(false);
    }
  } catch (error) {
    if (error.toString().includes("does not exist")) {
      console.warn(error);
    } else {
      statusActions.setErrorStatus(error);
    }
    setConnectionsLoading(false);
  }
}

function* modifyCorporatePackage(action) {
  try {
    if (isOnline()) {
      if (store.getState().connection.currentConnection.userType !== "owner") {
        throw throwError("custom", "Permission denied");
      }
      yield setConnectionsLoading(true);
      yield updateCorporatePackage(
        action.payload.data.corporatePackageId,
        action.payload.data.accepted
      );
      yield put({
        type: "SET_CORPORATE_PACKAGES",
        payload: {
          data: {
            [action.payload.data.corporatePackageId]: {
              ...store.getState().connection.corporatePackages[
                action.payload.data.corporatePackageId
              ],
              accepted: action.payload.data.accepted
            }
          }
        }
      });
    }

    yield setConnectionsLoading(false);
  } catch (error) {
    yield statusActions.setErrorStatus(error);
    yield setConnectionsLoading(false);
  }
}

export function* connectionWatcher() {
  yield all([takeEvery("SET_CONNECTIONS", setConnectionsWorker)]);
  yield all([takeEvery("SET_CURRENT_CONNECTION", setCurrentConnectionWorker)]);
  yield all([takeEvery("CREATE_NEW_STAFF", createNewStaffWorker)]);
  yield all([takeEvery("REMOVE_STAFF", removeStaffWorker)]);
  yield all([takeEvery("GET_CONNECTED_STAFFS", getConnectedStaffWorker)]);
  yield all([takeEvery("GET_CORPORATE_PACKAGES", getPartneredCorporates)]);
  yield all([takeEvery("UPDATE_CORPORATE_PACKAGE", modifyCorporatePackage)]);
  yield all([takeEvery("GET_BRAND_DATA", getBrandDataWorker)]);
  yield all([takeEvery("GET_BRAND_LOGO", getBrandLogoWorker)]);
}

function* setConnectionsLoading(bool) {
  yield put({
    type: "SET_CONNECTION_LOADING",
    payload: {
      loading: bool
    }
  });
}
