import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";
import ProgressTrack from "../../front-end-global-components/components/ProgressTrack/ProgressTrack";
import Header from "../../front-end-global-components/components/Header/Header";
import RoundedProfilePicture from "../../front-end-global-components/components/RoundedProfilePicture/RoundedProfilePicture";
import { corporateActions } from "../../redux/sagas/corporateSaga";
import { isOnline, isValidArray } from "../../utils/validators";
import ConditionalRender from "../../front-end-global-components/components/ConditionalRender/ConditionalRender";
import { statusActions } from "../../redux/sagas/statusSaga";
import Modal from "../../front-end-global-components/components/Modal/Modal";
import ReArrangeAndAddPrescription from "../../front-end-global-components/components/ReArrangeAndAddPrescription/ReArrangeAndAddPrescription";
import Button from "../../front-end-global-components/components/Button/Button";
import { RotateIcon } from "../../front-end-global-components/assets/assets";

function SelectedRequest(props) {
  const RemainingDays = (timestamp) => {
    const day = Math.round((timestamp - +new Date()) / 86400000);
    return `${day} DAY${day !== 1 && "S"} REMAINING`;
  };
  const fileInputFieldRef = useRef(null);
  const cropper = useRef(null);
  const [cropperReady, setCropperReady] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [deleteDocumentModal, showDeleteDocumentModal] = useState(false);
  const [file, setFile] = useState(null);
  const [src, setSrc] = useState([]);

  useEffect(() => {
    if (
      typeof props.corporate.selectedRequest !== "string" &&
      !isValidArray(props.corporate.checkups)
    ) {
      props.navigate("/");
    } else {
      setLoading(true);

      setSelectedRequest(
        props.corporate.checkups?.find(
          (data) => data.requestId === props.corporate.selectedRequest
        )
      );
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [props.corporate.checkups, props.corporate.selectedRequest]);

  const addDocumentOnChange = (event) => {
    if (event.target.files) {
      if (
        ["application/pdf", "image/png", "image/jpeg"].includes(
          event.target.files[0]?.type
        ) === false
      ) {
        return statusActions.setErrorStatus({
          code: "custom",
          message: "Kindly upload a valid File"
        });
      }
      if (
        ["image/png", "image/jpeg"].includes(event.target.files[0]?.type) ===
        true
      ) {
        setFile(URL.createObjectURL(event.target.files[0]));
      } else if (
        typeof event.target.files[0]?.size === "number" &&
        event.target.files[0].size <= 5000000
      ) {
        props.uploadFile({ type: "file", file: event.target.files[0] });
      } else {
        statusActions.setErrorStatus({
          code: "input/file-size-exceeded",
          message: "Document size should be less than 5MB"
        });
      }
    }
  };

  useEffect(() => {
    if (file) {
      setCropperReady(false);
      let canvas = document.getElementById("imageData");
      let ctx = canvas.getContext("2d");
      let maxW = 1024;
      let maxH = 1024;
      let img = new Image();
      img.onload = function () {
        let iw = img.width;
        let ih = img.height;
        let scale = Math.min(maxW / iw, maxH / ih);
        let iwScaled = iw * scale;
        let ihScaled = ih * scale;
        canvas.width = iwScaled;
        canvas.height = ihScaled;
        ctx.drawImage(img, 0, 0, iwScaled, ihScaled);
        cropper.current = new Cropper(canvas, {
          viewMode: 2,
          maxContainerWidth: 500,
          maxCanvasWidth: 100,
          maxCropBoxWidth: 20,
          background: false,
          borderRadius: 50,
          zoomable: true,
          dragMode: "move",
          responsive: true,
          guides: false,
          rotatable: true
        });
        setCropperReady(true);
      };
      img.src = file;
    }
  }, [file]);

  function cropHandler() {
    cropper.current.getCroppedCanvas().toBlob(
      (blob) => {
        //for blob to image
        const file = new File([blob], `${props.fileName - +new Date()}.png`, {
          type: "image/png"
        });
        setSrc([...src, file]);
      },
      "image/png",
      1
    );
    setShowModal(true);
    setFile("");
  }

  useEffect(() => {
    if (showModal === false) {
      setSrc([]);
    }
  }, [showModal]);

  return (
    <>
      <div className="inherit-parent-height flex-place-children-page-center ">
        <div className="inherit-parent-height inherit-parent-width large-screen-bg-color large-screen-height large-screen-width large-screen-border">
          {file && file !== null && (
            <>
              <div
                data-cy="cropper-modal"
                className="inherit-parent-height inherit-parent-width flex-center-children-horizontally flex-direction-column background-color-black border-radius-default"
              >
                <div className="inherit-parent-height inherit-parent-width max-height-90-percentage padding-top-default">
                  <canvas
                    className="display-block max-width-100-percentage"
                    id="imageData"
                    data-cy="cropper-canvas"
                  ></canvas>
                </div>
                <footer className="padding-larger inherit-parent-width flex-align-items-center flex-justify-content-space-between">
                  <Button
                    text="Cancel"
                    variant="transparent"
                    data-cy="crop-img-cancel-button"
                    className=" font-size-larger width-fit-content"
                    onClick={() => {
                      setFile("");
                    }}
                  />

                  <div
                    data-cy="crop-img-rotate-button"
                    onClick={() => {
                      cropper.current.rotate(90);
                      cropper.current.zoomTo(0);
                    }}
                  >
                    <RotateIcon />
                  </div>

                  <Button
                    text="Save"
                    data-cy="crop-img-save-button"
                    variant="transparent"
                    className="font-size-larger width-fit-content"
                    disabled={cropperReady === false}
                    onClick={(event) => {
                      if (
                        cropper.current.getData(true).width <= 50 ||
                        cropper.current.getData(true).height <= 50
                      ) {
                        return;
                      }
                      cropHandler(event);
                    }}
                  />
                </footer>
              </div>
            </>
          )}
          {!file && (
            <>
              <Header
                removeResponsive={true}
                title="Checkup status"
                backButtonOnClick={() => {
                  props.clearSelectedRequest();
                  props.navigate("/");
                }}
                hideBackButton
                supportIconOnClick={() => {}}
              />
              <div className="remaining-body-height inherit-parent-width padding-vertical-larger font-color-secondary overflow-y-scroll hide-scrollbar">
                <ConditionalRender
                  condition={props.corporate.loading || loading}
                >
                  <SelectedRequestSuspense />
                </ConditionalRender>

                <ConditionalRender
                  condition={!props.corporate.loading && !loading}
                >
                  <div className="padding-horizontal-larger">
                    <div className="flex-justify-content-space-between">
                      <div className="flex-center-children-vertically margin-top-small">
                        <div>
                          <RoundedProfilePicture src={""} size="small" />
                        </div>
                        <div className="font-family-gilroy-regular margin-left-small text-capitalize padding-default">
                          {selectedRequest?.name}
                        </div>
                      </div>

                      <div className="flex-direction-column flex-center-children-horizontally">
                        <div
                          data-cy="status"
                          className="font-size-small text-align-right font-family-gilroy-regular"
                        >
                          {selectedRequest?.status}
                        </div>
                        <div className="font-size-smaller text-align-right font-family-gilroy-light margin-top-small">
                          {RemainingDays(selectedRequest?.expiringAt)}
                        </div>
                      </div>
                    </div>

                    <div className="margin-top-medium">
                      <div className="font-size-smaller font-family-gilroy-regular">
                        DESCRIPTION
                      </div>
                      <div className="font-size-large font-family-gilroy-regular margin-top-small text-capitalize">
                        {selectedRequest?.description}
                      </div>
                    </div>
                  </div>

                  <div className="background-color-grey height-2px margin-top-large" />

                  <div className="padding-horizontal-larger">
                    <ProgressTrack
                      data={selectedRequest}
                      onFileUploadOnChange={(event) => {
                        addDocumentOnChange(event);
                      }}
                      isDownloadUrlPresent={
                        props.corporate?.downloadableURLs &&
                        Object.keys(props.corporate?.downloadableURLs).includes(
                          selectedRequest?.documentId
                        )
                      }
                      connection={props.connection}
                      healthCheckupOnClick={() => {
                        if (isOnline()) {
                          props.updateCheckupStatus({
                            id: props.corporate.selectedRequest,
                            status: "COMPLETED"
                          });
                          setSelectedRequest({
                            ...selectedRequest,
                            status: "COMPLETED"
                          });
                        }
                      }}
                      deleteOnclick={() => {
                        showDeleteDocumentModal(!deleteDocumentModal);
                      }}
                      pdfOnclick={() => {
                        if (
                          props.corporate?.downloadableURLs[
                            selectedRequest.documentId
                          ]
                        ) {
                          props.navigate(
                            `/documents/${selectedRequest.documentId}`
                          );
                        }
                      }}
                      // healthCheckUpSliderStatus={(status) => {
                      //   if (status === true) {
                      //     props.updateCheckupStatus({
                      //       id: props.corporate.selectedRequest,
                      //       status: "COMPLETED"
                      //     });
                      //   }
                      // }}
                      // setCompletedStatus={
                      //   selectedRequest?.status !== "COMPLETED"? false : true
                      // }
                      userType="clinic"
                      options={["appointment", "healthCheckup", "report"]}
                    />
                  </div>
                </ConditionalRender>
              </div>
            </>
          )}
        </div>
      </div>
      <Modal
        show={isValidArray(src) && src && showModal && !file}
        canIgnore={true}
        onClose={() => {
          setShowModal(!showModal);
        }}
        position={
          props.windowInnerWidth > 576
            ? ""
            : "position-fixed bottom-0 left-0 right-0"
        }
        width="inherit-parent-width"
        maxWidth={props.windowInnerWidth < 576 ? "" : "max-width-500px"}
        background="false"
        boxShadow="false"
        borderRadius="false"
        height="height-90-percent"
      >
        <div
          data-cy="upload-modal"
          className={`background-white inherit-parent-height padding-top-larger  ${
            props.windowInnerWidth < 576
              ? "border-radius-top-default"
              : "border-radius-default"
          } box-shadow-default font-family-gilroy-regular font-color-secondary`}
        >
          <ReArrangeAndAddPrescription
            src={src}
            setSrc={setSrc}
            onAddExtraImageClick={() => {
              fileInputFieldRef.current.click();
            }}
            uploadButtonText="Upload Document"
            fileName={`${+new Date()}`}
            onAddPrescriptionClick={(arrayList) => {
              props.uploadFile({ type: "image", file: arrayList });
              setShowModal(!showModal);
            }}
            // isLoading={props.documents.loading}
          />
        </div>
      </Modal>
      {/* cropper modal */}

      {/* delete Document modal*/}

      <Modal
        show={deleteDocumentModal}
        canIgnore={true}
        onClose={() => {
          showDeleteDocumentModal(!deleteDocumentModal);
        }}
        position={
          props.windowInnerWidth > 576
            ? ""
            : "position-fixed bottom-0 left-0 right-0"
        }
        width="inherit-parent-width"
        maxWidth={props.windowInnerWidth < 576 ? "" : "max-width-500px"}
        background="false"
        boxShadow="false"
        borderRadius="false"
        height="height-fit-to-content"
      >
        <div
          data-cy="logout-confirmation-modal"
          className={`background-white padding-larger ${
            props.windowInnerWidth < 576
              ? "border-radius-top-default"
              : "border-radius-default"
          } box-shadow-default font-family-gilroy-regular font-color-secondary`}
        >
          <div className="font-family-gilroy-medium text-align-center margin-bottom-larger padding-top-large">
            Are you sure you want to delete the document?
          </div>

          <div className=" flex-justify-content-space-around padding-top-medium margin-bottom-large">
            <Button
              data-cy="confirm-logout-button"
              text="Delete"
              type="button"
              variant="danger"
              className=" margin-right-large"
              boxShadow={false}
              onClick={() => {
                props.deleteFile();
                showDeleteDocumentModal(false);
              }}
            />
            <Button
              data-cy="logout-cancel-button"
              text="Cancel"
              variant="secondary"
              className=" margin-left-large"
              boxShadow={false}
              onClick={() => {
                showDeleteDocumentModal(false);
              }}
            />
          </div>
        </div>
      </Modal>

      <input
        ref={fileInputFieldRef}
        type="file"
        accept="image/jpeg,image/png"
        name="fileUploaderInput"
        label="inputFile"
        onClick={(event) => {
          event.target.value = "";
        }}
        className="display-none"
        onChange={(event) => {
          addDocumentOnChange(event);
        }}
      />
    </>
  );
}

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    corporate: state.corporate,
    connection: state.connection
  };
};

const mapDispatchToProps = function () {
  return {
    clearSelectedRequest: () => corporateActions.clearSelectedRequest(),
    updateCheckupStatus: (data) => corporateActions.updateCheckupRequest(data),
    uploadFile: (data) => corporateActions.uploadReport(data),
    deleteFile: () => corporateActions.deleteReport()
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectedRequest);

function SelectedRequestSuspense() {
  return (
    <div>
      <div className=" flex-justify-content-space-between padding-left-default padding-right-large flex-center-children-vertically">
        <div className=" flex-center-children-vertically">
          <div className="shimmer  border-radius-100-percentage padding-small margin-default height-and-width-84px">
            <div className="padding-medium margin-default border-radius-default" />
          </div>
          <div className="shimmer width-100px height-25px margin-right-default border-radius-default " />
        </div>
        <div className="shimmer width-100px height-25px margin-left-default border-radius-default" />
      </div>
      <div className="shimmer width-100px height-16px margin-left-large border-radius-default margin-top-medium" />
      <div className="shimmer width-250px height-25px margin-left-large border-radius-default margin-top-small" />
      <div className="shimmer border-radius-default height-4px margin-top-larger" />
      <div className=" flex-justify-content-space-between padding-horizontal-large flex-center-children-vertically margin-top-large">
        <div className="shimmer width-150px height-25px border-radius-default" />
        <div className="shimmer width-30px height-25px border-radius-default" />
      </div>
      {[...Array(3).keys()].map((index) => (
        <div
          key={index}
          className="shimmer margin-large border-radius-default min-height-70px"
        />
      ))}
    </div>
  );
}
