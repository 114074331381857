import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import Login from "./screens/Login/Login";
import SnackBar from "./front-end-global-components/components/SnackBar/SnackBar";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useAuthSubscriber } from "./services/authentication";
import {
  useCheckupsListenerForOwner,
  useCheckupsListenerForStaffs,
  useConnectionsListener,
  useProfileListener
} from "./services/database";
import LogRocket from "logrocket";
import Signup from "./screens/Signup/Signup";
import Home from "./screens/Home/Home";
import CreateClinic from "./screens/CreateClinic/CreateClinic";
import Setting from "./screens/Setting/Setting";
import { authActions } from "./redux/sagas/authSaga";
import { isValidArray, isValidObject } from "./utils/validators";
import { connectionActions } from "./redux/sagas/connectionSaga";
import Personnel from "./screens/Personnel/Personnel";
import Modal from "./front-end-global-components/components/Modal/Modal";
import Button from "./front-end-global-components/components/Button/Button";
import InputBox from "./front-end-global-components/components/InputBox/InputBox";
import { profileActions } from "./redux/sagas/profileSaga";
import SelectedRequest from "./screens/SelectedRequest/SelectedRequest";
import ViewDocument from "./screens/ViewDocument/ViewDocument";
import { appActions } from "./redux/sagas/appSaga";
import reloadUpdate from "./front-end-global-components/assets/reload_update.svg";

function App(props) {
  const [status, setStatus] = useState(null);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [staffConnectionIds, setStaffsConnectionIds] = useState(null);
  const [ownerBrandIds, setOwnersBrandIds] = useState(null);
  const [windowInnerWidth, setWindowInnerWidth] = useState(window.innerWidth);
  const [showAddUserNameModal, setShowAddUserNameModal] = useState(false);
  const [addUserNameFormData, setAddUserNameFormData] = useState({
    userName: ""
  });
  const navigate = useNavigate();
  const isAuthed = useAuthSubscriber();

  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const getWindowSize = () => {
    setWindowInnerWidth(window.innerWidth);
  };

  const getNetworkStatusChange = () => {
    setIsOnline(navigator.onLine);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener("resize", getWindowSize);
    return () => {
      window.removeEventListener("resize", getWindowSize);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.addEventListener("online", getNetworkStatusChange);
    window.addEventListener("offline", getNetworkStatusChange);
    return () => {
      window.removeEventListener("online", getNetworkStatusChange);
      window.removeEventListener("offline", getNetworkStatusChange);
    };
    // eslint-disable-next-line
  }, [isOnline]);

  useEffect(() => {
    props.setIsOnline(isOnline);
    // eslint-disable-next-line
  }, [isOnline]);

  useEffect(() => {
    if (
      process.env.NODE_ENV === "production" &&
      window.location.hostname !== "localhost"
    ) {
      const release =
        typeof process.env.REACT_APP_BUILD_NUM === "string"
          ? { release: process.env.REACT_APP_BUILD_NUM }
          : {};

      switch (process.env.REACT_APP_STAGING) {
        case "uat":
          LogRocket.init("byepo/corporate-clinics-uat", release);
          break;

        case "dev":
          LogRocket.init("byepo/corporate-clinics-dev", release);
          break;

        default:
          break;
      }
    }
  }, []);

  useProfileListener({
    uid: props.auth.data.uid,
    phoneNumber: props.auth.data.phoneNumber,
    isAuthed: isAuthed
  });

  useConnectionsListener({
    uid: props.profile.data && Object.keys(props.profile.data)[0],
    phoneNumber: props.auth.data.phoneNumber,
    isAuthed: isAuthed
  });

  useCheckupsListenerForOwner({
    brandIds: isValidArray(ownerBrandIds) && ownerBrandIds,
    isAuthed: isAuthed
  });

  useCheckupsListenerForStaffs({
    connectionIds: isValidArray(staffConnectionIds) && staffConnectionIds,
    isAuthed: isAuthed
  });

  //useEffect for error handling
  useEffect(() => {
    if (props.status.message) {
      setStatus(props.status.message);
    } else {
      setStatus(null);
    }
  }, [props.status]);

  useEffect(() => {
    if (isValidObject(props.connection.brands)) {
      props.getCorporatePackages(Object.keys(props.connection.brands));
    }
    // eslint-disable-next-line
  }, [props.connection.brands]);

  useEffect(() => {
    if (isValidArray(props.connection.data)) {
      let connectionId = {};
      let ownerBrandId = {};
      props.connection.data.forEach((data) => {
        if (data.userType !== "owner") {
          connectionId = {
            ...connectionId,
            [data.connectionId]: data.connectionId
          };
        }
      });

      props.connection.data.forEach((data) => {
        if (data.userType === "owner") {
          ownerBrandId = { ...ownerBrandId, [data.brandId]: data.brandId };
        }
      });
      setStaffsConnectionIds(Object.keys(connectionId));
      setOwnersBrandIds(Object.keys(ownerBrandId));
    }
    // eslint-disable-next-line
  }, [props.connection.data]);

  useEffect(() => {
    if (props.connection.data) {
      props.setCurrentConnection(
        props.connection.data &&
          props.connection.data[props.connection.data.length - 1]
      );
    }
    // eslint-disable-next-line
  }, [props.connection.data]);

  useEffect(() => {
    if (!props.auth.data.uid) {
      return;
    }
    if (!isValidObject(props.profile.data)) {
      return;
    }
    if (
      isAuthed &&
      (props.profile.data[Object.keys(props.profile.data)[0]]?.fullName ===
        undefined ||
        props.profile.data[Object.keys(props.profile.data)[0]]?.fullName ===
          null)
    ) {
      setShowAddUserNameModal(true);
    } else {
      setShowAddUserNameModal(false);
    }
  }, [props.profile.data, props.auth.data, isAuthed]);

  return (
    <>
      <Routes>
        <Route
          path="/login"
          element={
            <PublicRoute uid={props.auth.data.uid}>
              <Login windowInnerWidth={windowInnerWidth} navigate={navigate} />
            </PublicRoute>
          }
        />

        <Route
          path="/signup"
          element={
            <PublicRoute uid={props.auth.data.uid}>
              <Signup windowInnerWidth={windowInnerWidth} navigate={navigate} />
            </PublicRoute>
          }
        />
        <Route
          path="/create"
          element={
            <ProtectedRoute
              uid={props.auth.data.uid}
              profileData={props.profile.data}
              connectionData={props.connection.data}
              isCreationPage={true}
            >
              <CreateClinic
                windowInnerWidth={windowInnerWidth}
                navigate={navigate}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="/settings"
          element={
            <ProtectedRoute
              uid={props.auth.data.uid}
              profileData={props.profile.data}
              connectionData={props.connection.data}
            >
              <Setting
                windowInnerWidth={windowInnerWidth}
                navigate={navigate}
                logoutOnClick={() => {
                  setShowLogoutModal(true);
                }}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="/personnel/:branchId"
          element={
            <ProtectedRoute
              uid={props.auth.data.uid}
              profileData={props.profile.data}
              connectionData={props.connection.data}
            >
              {props.connection.currentConnection?.userType !== "staff" && (
                <Personnel
                  windowInnerWidth={windowInnerWidth}
                  navigate={navigate}
                />
              )}
            </ProtectedRoute>
          }
        />
        <Route
          path="/documents/:documentId"
          element={
            <ProtectedRoute
              uid={props.auth.data.uid}
              profileData={props.profile.data}
              connectionData={props.connection.data}
            >
              <ViewDocument
                windowInnerWidth={windowInnerWidth}
                navigate={navigate}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="/"
          element={
            <ProtectedRoute
              uid={props.auth.data.uid}
              profileData={props.profile.data}
              connectionData={props.connection.data}
            >
              <Home
                windowInnerWidth={windowInnerWidth}
                navigate={navigate}
                isNamePresent={showAddUserNameModal}
              />
            </ProtectedRoute>
          }
        />

        <Route
          path="/request"
          element={
            <ProtectedRoute
              uid={props.auth.data.uid}
              profileData={props.profile.data}
              connectionData={props.connection.data}
            >
              <SelectedRequest
                windowInnerWidth={windowInnerWidth}
                navigate={navigate}
              />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <Modal
        show={showAddUserNameModal}
        canIgnore={false}
        onClose={() => {
          setShowAddUserNameModal(!showAddUserNameModal);
        }}
        position={
          windowInnerWidth > 576 ? "" : "position-fixed bottom-0 left-0 right-0"
        }
        width="inherit-parent-width"
        maxWidth={windowInnerWidth < 576 ? "" : "max-width-500px"}
        background="false"
        boxShadow="false"
        borderRadius="false"
        height="height-fit-to-content"
      >
        <div
          className={`background-white padding-larger ${
            windowInnerWidth < 576
              ? "border-radius-top-default"
              : "border-radius-default"
          } box-shadow-default font-family-gilroy-regular font-color-secondary`}
        >
          <div className=" text-align-center font-family-gilroy-medium">
            Welcome! What is your name?
          </div>
          <form
            onChange={(event) => {
              setAddUserNameFormData({
                ...addUserNameFormData,
                [event.target.name]: event.target.value
              });
            }}
            onSubmit={(event) => {
              event.preventDefault();
              props.editProfile(addUserNameFormData);
              setShowAddUserNameModal(false);
            }}
          >
            <InputBox
              className="inherit-parent-width margin-top-larger"
              label="Your name"
              type="text"
              removeResponsive={true}
              name="userName"
              notes="Please enter name as per your official ID"
              value={addUserNameFormData.userName}
            />
            <Button
              boxShadow={false}
              disabled={addUserNameFormData.userName ? false : true}
              className="margin-top-larger font-family-gilroy-medium"
              variant="primary"
              text="Continue"
              type="submit"
            />
          </form>
        </div>
      </Modal>
      <Modal
        show={showLogoutModal}
        canIgnore={true}
        onClose={() => {
          setShowLogoutModal(!showLogoutModal);
        }}
        position={
          windowInnerWidth > 576 ? "" : "position-fixed bottom-0 left-0 right-0"
        }
        width="inherit-parent-width"
        maxWidth={windowInnerWidth < 576 ? "" : "max-width-500px"}
        background="false"
        boxShadow="false"
        borderRadius="false"
        height="height-fit-to-content"
      >
        <div
          data-cy="logout-confirmation-modal"
          className={`background-white padding-larger ${
            windowInnerWidth < 576
              ? "border-radius-top-default"
              : "border-radius-default"
          } box-shadow-default font-family-gilroy-regular font-color-secondary`}
        >
          <div className="font-family-gilroy-medium text-align-center margin-bottom-larger padding-top-large">
            Are you sure you want to logout?
          </div>

          <div className=" flex-justify-content-space-around padding-top-medium margin-bottom-large">
            <Button
              data-cy="confirm-logout-button"
              text="Logout"
              type="button"
              variant="danger"
              className=" margin-right-large"
              boxShadow={false}
              onClick={() => {
                props.logout();
                setShowLogoutModal(false);
              }}
            />
            <Button
              data-cy="logout-cancel-button"
              text="Cancel"
              variant="secondary"
              className=" margin-left-large"
              boxShadow={false}
              onClick={() => {
                setShowLogoutModal(false);
              }}
            />
          </div>
        </div>
      </Modal>
      <SnackBar
        message={status}
        status={props.status}
        type={props.status.code === null ? "success" : "error"}
      />
      <div
        id="critical-update-card"
        className=" display-none inherit-parent-height inherit-parent-width background-color-black-with-opacity-light z-index-101 position-absolute-center-self "
      >
        <footer className="position-absolute  inherit-parent-width bottom-0 padding-large">
          <div
            id="critical-update-card-contents-wrapper"
            className="background-color-white inherit-parent-width padding-large border-radius-default display-flex flex-direction-column flex-align-items-center "
          >
            <img
              src={reloadUpdate}
              alt="update"
              className=" margin-bottom-default"
            />
            <p className="font-family-gilroy-medium font-size-medium font-color-secondary text-align-center margin-bottom-default ">
              Critical update available
            </p>
            <p className="font-family-gilroy-regular font-size-medium font-color-secondary text-align-center margin-bottom-default ">
              New critical changes updated and ready to launch.
            </p>
          </div>
        </footer>
      </div>
    </>
  );
}

const ProtectedRoute = ({
  uid,
  profileData,
  connectionData,
  isCreationPage,
  children
}) => {
  if (uid === null && profileData === null) {
    return <Navigate to="/login" />;
  } else if (
    typeof uid === "string" &&
    Array.isArray(connectionData) &&
    isCreationPage !== true &&
    !isValidArray(connectionData)
  ) {
    return <Navigate to="/create" />;
  }
  return children;
};

const PublicRoute = ({ uid, children }) => {
  if (typeof uid === "string") {
    return <Navigate to="/" />;
  }
  return children;
};

const mapStateToProps = function (state) {
  return {
    status: state.status,
    auth: state.auth,
    profile: state.profile,
    connection: state.connection
  };
};

const mapDispatchToProps = function () {
  return {
    logout: () => authActions.logout(),
    setCurrentConnection: (data) =>
      connectionActions.setCurrentConnection(data),
    editProfile: (data) => profileActions.editProfile(data),
    getCorporatePackages: (data) =>
      connectionActions.getCorporatePackages(data),
    setIsOnline: (data) => appActions.putOnline(data)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
