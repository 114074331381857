import React, { useEffect, useState } from "react";
import Header from "../../front-end-global-components/components/Header/Header";
import Modal from "../../front-end-global-components/components/Modal/Modal";
import RoundedProfilePicture from "../../front-end-global-components/components/RoundedProfilePicture/RoundedProfilePicture";
import arrow from "../../front-end-global-components/assets/DropDownArrow.svg";
import setting from "../../assets/icons/Settings.svg";
import { connect } from "react-redux";
import ProfileCards from "../../components/ProfileCards/ProfileCards";
import "./Home.css";
import { connectionActions } from "../../redux/sagas/connectionSaga";
import { isValidArray, isValidObject } from "../../utils/validators";
import wait from "../../assets/icons/waitingIcon.svg";
import Button from "../../front-end-global-components/components/Button/Button";
import warning from "../../front-end-global-components/assets/warning_icon.svg";
import ConditionalRender from "../../front-end-global-components/components/ConditionalRender/ConditionalRender";
import { HealthCheckupTimeline } from "../../front-end-global-components/components/HealthCheckupTimeline/HealthCheckupTimeline";
import { corporateActions } from "../../redux/sagas/corporateSaga";
import empty from "../../front-end-global-components/assets/images/fallback.svg";

function Home(props) {
  const [showModal, setShowModal] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState("");

  useEffect(() => {
    if (!isValidObject(props.connection.currentConnection)) {
      return;
    }
    if (!Object.keys(props.connection.currentConnection).includes("verified")) {
      setVerificationStatus("notVerified");
      return;
    }
    if (props.connection.currentConnection.verified === false) {
      setVerificationStatus("failed");
    } else if (props.connection.currentConnection.verified === true) {
      setVerificationStatus("verified");
    }
  }, [props.connection.data, props.connection.currentConnection]);

  return (
    <>
      <ConditionalRender
        condition={verificationStatus && verificationStatus === "verified"}
      >
        <>
          <div
            data-cy="home-page-content"
            className="inherit-parent-height flex-place-children-page-center"
          >
            <div className="inherit-parent-height inherit-parent-width large-screen-bg-color large-screen-height large-screen-width large-screen-border">
              <Header
                removeResponsive={true}
                userType="corporate"
                headerPositionLeft={true}
                hideBackButton={true}
                maxStepperCount={0}
                currentStepperValue={0}
                supportIconOnClick={() => {}}
              />
              <div className="remaining-body-height flex-direction-column flex-justify-content-space-between">
                <div className="inherit-parent-height">
                  <div className="padding-left-larger padding-right-larger padding-bottom-large">
                    <ClinicSwitcher
                      data={
                        props.connection.currentConnection &&
                        props.connection.currentConnection
                      }
                      connection={props.connection}
                      dropDownOnClick={() => {
                        setShowModal(true);
                      }}
                      settingOnClick={() => {
                        props.navigate("/settings");
                      }}
                    />
                  </div>
                  <div className="container-height overflow-y-scroll hide-scrollbar">
                    <ConditionalRender
                      condition={isValidArray(props.corporate.checkups)}
                    >
                      <HealthCheckupTimeline
                        cardType="clinic"
                        filter={["active", "completed"]}
                        checkupsData={
                          props.corporate.checkups &&
                          props.corporate.checkups.filter(
                            (data) =>
                              data.clinicId ===
                              props.connection?.currentConnection?.connectionId
                          )
                        }
                        connection={props.connection}
                        cardOnClick={(data) => {
                          props.setSelectedRequest(data.requestId);
                          props.navigate("/request");
                        }}
                      />
                    </ConditionalRender>
                    <ConditionalRender
                      condition={
                        !props.corporate.loading &&
                        !props.connection.loading &&
                        !isValidArray(props.corporate.checkups)
                      }
                    >
                      <NoRequestAvailable cardType={"clinic"} />
                    </ConditionalRender>

                    <ConditionalRender
                      condition={
                        props.corporate.loading || props.connection.loading
                      }
                    >
                      <TimelineSuspense />
                    </ConditionalRender>
                  </div>
                </div>
              </div>
              <Modal
                show={showModal}
                canIgnore={true}
                onClose={() => {
                  setShowModal(!showModal);
                }}
                position={
                  props.windowInnerWidth > 576
                    ? ""
                    : "position-fixed bottom-0 left-0 right-0"
                }
                width="inherit-parent-width"
                maxWidth={props.windowInnerWidth < 576 ? "" : "max-width-500px"}
                background="false"
                boxShadow="false"
                borderRadius="false"
                height="height-fit-to-content"
              >
                <div
                  data-cy="switcher-modal"
                  className={`background-white padding-left-larger padding-bottom-larger padding-top-larger ${
                    props.windowInnerWidth < 576
                      ? "border-radius-top-default"
                      : "border-radius-default"
                  } box-shadow-default font-family-gilroy-regular font-color-secondary`}
                >
                  <div
                    className={`${
                      props.connection.data && props.connection.data.length > 3
                        ? "height-224px overflow-y-scroll overflow-x-hidden"
                        : ""
                    }`}
                  >
                    {props.connection.currentConnection &&
                      props.connection.data &&
                      props.connection.data.map((data, index) => (
                        <ProfileCards
                          connection={props.connection}
                          selected={
                            props.connection.currentConnection.connectionId ===
                            data.connectionId
                          }
                          key={index}
                          data={data}
                          onCardClick={() => {
                            if (
                              props.connection.currentConnection
                                .connectionId !== data.connectionId
                            ) {
                              props.setCurrentConnection(data);
                            }
                          }}
                        />
                      ))}
                    <div
                      className={`${
                        props.connection.data &&
                        props.connection.data.length > 3
                          ? "padding-top-default position-sticky bottom-0 background-color-white"
                          : ""
                      }`}
                    >
                      <ProfileCards
                        type="add"
                        text="Add Clinic"
                        addOnClick={() => {
                          props.navigate("/create");
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </>
      </ConditionalRender>
      <ConditionalRender
        condition={verificationStatus && verificationStatus !== "verified"}
      >
        <Verification
          status={verificationStatus}
          showHomeButton={
            props.connection.data && props.connection.data.length > 1
              ? true
              : false
          }
          tryAgainOnClick={() => {
            props.navigate("/create");
          }}
          homeOnClick={() => {
            props.setCurrentConnection(
              props.connection.data.filter((data) => data.verified === true)[0]
            );
          }}
        />
      </ConditionalRender>
    </>
  );
}

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    connection: state.connection,
    corporate: state.corporate
  };
};

const mapDispatchToProps = function () {
  return {
    setCurrentConnection: (data) =>
      connectionActions.setCurrentConnection(data),
    setSelectedRequest: (data) => corporateActions.setSelectedRequest(data)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);

const ClinicSwitcher = (props) => {
  return (
    <div className="inherit-parent-width flex-justify-content-space-between flex-center-children-vertically padding-vertical-large">
      <div
        data-cy="drop-down-button"
        className="display-flex cursor-pointer"
        onClick={props.dropDownOnClick}
      >
        <div className=" padding-right-default">
          <RoundedProfilePicture
            size="small"
            userType="corporate"
            src={
              props.connection?.brands &&
              props.connection?.brands[props.data?.brandId]?.downloadURL
            }
          />
        </div>
        <div className="padding-left-default flex-direction-column flex-center-children-horizontally">
          <div className=" display-flex flex-center-children-vertically">
            <div className=" font-size-large padding-right-default">
              {props.data?.companyName ? props.data.companyName : "All clinics"}
            </div>
            <img src={arrow} alt="arrow" />
          </div>
          <ConditionalRender
            condition={typeof props.data.locality === "string"}
          >
            <div className=" font-size-small padding-top-small">
              {props.data?.locality ? props.data.locality : ""}
            </div>
          </ConditionalRender>
        </div>
      </div>
      <div
        data-cy="setting-icon"
        className="cursor-pointer"
        onClick={props.settingOnClick}
      >
        <img className="" src={setting} alt="setting" />
      </div>
    </div>
  );
};

function Verification(props) {
  return (
    <div className="inherit-parent-height flex-place-children-page-center">
      <div className="inherit-parent-height flex-place-children-page-center padding-larger inherit-parent-width large-screen-bg-color large-screen-height large-screen-width large-screen-border">
        {props.status === "failed" ? (
          <>
            <div className=" flex-center-children-horizontally">
              <img
                className=" height-width-32px margin-bottom-larger"
                src={warning}
                alt="waiting"
              />
            </div>
            <div className="text-align-center font-size-large font-family-gilroy-medium padding-horizontal-large">
              Verification failed
            </div>
            <div className=" text-align-center font-size-large padding-vertical-large">
              Please try again or contact our technical team
            </div>
            <Button
              type="button"
              className="margin-bottom-large"
              text="Try again"
              boxShadow={false}
              onClick={props.tryAgainOnClick}
            />
            {props.showHomeButton && (
              <Button
                type="button"
                variant="secondary"
                className="margin-bottom-large"
                text="Home"
                boxShadow={false}
                onClick={props.homeOnClick}
              />
            )}
            <div className="text-align-center  padding-vertical-large">
              <span className=" font-color-primary">Contact</span> our experts
              for help.
            </div>
          </>
        ) : (
          <>
            <div className=" flex-center-children-horizontally">
              <img
                className=" height-width-32px margin-bottom-larger"
                src={wait}
                alt="waiting"
              />
            </div>
            <div className="text-align-center font-size-large font-family-gilroy-medium padding-horizontal-large">
              Setup complete!
            </div>
            <div className=" text-align-center font-size-large padding-vertical-large">
              Please wait while our team verifies the information provide.
            </div>
            {props.showHomeButton ? (
              <Button
                type="button"
                className="margin-bottom-large"
                text="Home"
                boxShadow={false}
                onClick={props.homeOnClick}
              />
            ) : (
              <div className="text-align-center padding-vertical-large">
                You can <span className=" font-color-primary">Log in</span> once
                your account is verified
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

const NoRequestAvailable = (props) => {
  return (
    <div className="inherit-parent-height flex-direction-column padding-horizontal-larger flex-center-children-horizontally flex-center-children-vertically inherit-parent-width">
      <div className="padding-top-larger">
        <img height={148} width={120} src={empty} alt="empty" />
      </div>

      <div className=" font-family-gilroy-medium padding-top-larger">
        {props.cardType === "clinic"
          ? "No appointment available"
          : "No requests available."}
      </div>
      <div className=" text-align-center padding-top-larger">
        {props.cardType === "corporate"
          ? "Please create a new request to send to your employee(s) for their health checkup."
          : props.cardType === "clinic"
          ? "Once a patient books an appointment with your clinic, the checkup requests appear here."
          : " The checkup requests appear here once your employer sends a request"}
      </div>
      {props.cardType === "corporate" && (
        <Button
          data-cy="create-new-request-button"
          type="button"
          className="margin-top-larger"
          text="Create new request"
          onClick={props.createNewRequestOnClick}
          boxShadow={false}
        />
      )}
    </div>
  );
};

function TimelineSuspense() {
  return (
    <div className=" padding-left-larger padding-right-larger inherit-parent-width ">
      <div className="shimmer border-radius-default min-height-70px" />
      {[...Array(10).keys()].map((index) => (
        <div
          key={index}
          className="shimmer margin-top-larger border-radius-default min-height-70px"
        />
      ))}
    </div>
  );
}
