import React from "react";
import ReactDOM from "react-dom";
import "./front-end-global-components/design-system.css";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store, { persistor } from "./redux/store/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter } from "react-router-dom";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import ErrorBoundary from "../src/front-end-global-components/components/ErrorBoundary/ErrorBoundary";

ReactDOM.render(
  <ErrorBoundary>
    <React.StrictMode>
      <BrowserRouter>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <App />
          </PersistGate>
        </Provider>
      </BrowserRouter>
    </React.StrictMode>
  </ErrorBoundary>,
  document.getElementById("root")
);

if (window.Cypress) {
  window.store = store;
  serviceWorkerRegistration.unregister();
} else {
  serviceWorkerRegistration.register();
  criticalUpdate();
}

const channel = new BroadcastChannel("ninto-clinic-update-channel");

const skipWaitingClearCacheAndReload = async (precacheList) => {
  if (precacheList.length > 1) {
    for (const [index, key] of precacheList.entries()) {
      if (index === precacheList.length - 1) {
        continue;
      }
      await caches.delete(key);
    }
    channel.postMessage("FORCE_UPDATE");
    await new Promise((resolve) => setTimeout(resolve, 500));
    window.location.reload(true);
  }
};

channel.onmessage = async function (e) {
  if (e.data === "FORCE_UPDATE") {
    return;
  }

  criticalUpdate();
};
async function criticalUpdate() {
  const keyList = await caches.keys();

  let precacheList = keyList
    .filter((key) => key.includes("ninto-precache"))
    .sort();

  if (precacheList.length <= 1) {
    return;
  }

  if (precacheList[precacheList.length - 1].includes("--force")) {
    const button = document.createElement("button");
    const cls = [
      "inherit-parent-width",
      "padding-vertical-medium",
      "font-family-gilroy-bold",
      "border-radius-default",
      "font-size-medium",
      "cursor-pointer",
      "filled-button-background",
      "font-color-white",
      "box-shadow-none"
    ];

    document.activeElement.blur();
    button.classList.add(...cls);
    button.innerHTML = "Okay";

    button.addEventListener("click", () =>
      skipWaitingClearCacheAndReload(precacheList)
    );

    const criticalUpdateCardContentsWrapper = document.getElementById(
      "critical-update-card-contents-wrapper"
    );

    criticalUpdateCardContentsWrapper.appendChild(button);

    const criticalUpdateCard = document.getElementById("critical-update-card");
    criticalUpdateCard.classList.remove("display-none");
    criticalUpdateCard.classList.add("display-block");
  }
}

reportWebVitals();
