import React, { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import ProfileCards from "../../components/ProfileCards/ProfileCards";
import Button from "../../front-end-global-components/components/Button/Button";
import ChipInputBox from "../../front-end-global-components/components/ChipInputBox/ChipInputBox";
import Header from "../../front-end-global-components/components/Header/Header";
import InputBox from "../../front-end-global-components/components/InputBox/InputBox";
import Modal from "../../front-end-global-components/components/Modal/Modal";
import { connectionActions } from "../../redux/sagas/connectionSaga";
import { isValidArray, isValidObject } from "../../utils/validators";

function Personnel(props) {
  const [activeElement, setActiveElement] = useState();
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);
  const { branchId } = useParams();
  const [showAddManagerModal, setShowAddManagerModal] = useState(false);
  const [showAddStaffModal, setShowAddStaffModal] = useState(false);
  const [personnelFormData, setPersonnelFormData] = useState({
    managers: [],
    staffs: []
  });
  const [currentStaffData, setCurrentStaffData] = useState({
    phoneNumber: "+91"
  });
  const [selectedConnection, setSelectedConnection] = useState(null);

  const onOutOfFocus = (event) => {
    setTimeout(() => {
      setActiveElement(event.target.name);
    }, 100);
  };

  useEffect(() => {
    props.getConnectedStaffs({ branchId: branchId });

    if (
      isValidArray(props.connection.data) &&
      props.connection.data.find(
        (connection) => connection.connectionId === branchId
      )
    ) {
      return setSelectedConnection(
        props.connection.data.find(
          (connection) => connection.connectionId === branchId
        )
      );
    } else {
      props.navigate("/settings");
    }

    // eslint-disable-next-line
  }, [props.connection.data]);

  return (
    <>
      <div className="inherit-parent-height flex-place-children-page-center ">
        <div className="inherit-parent-height inherit-parent-width large-screen-bg-color large-screen-height large-screen-width large-screen-border">
          <Header
            removeResponsive={true}
            title="Clinic personnel"
            backButtonOnClick={() => {
              props.navigate("/settings");
            }}
            supportIconOnClick={() => {}}
          />
          <div className="remaining-body-height flex-center-children-horizontally">
            <div className="padding-larger inherit-parent-width max-width-500px">
              <ProfileCards
                connection={props.connection}
                data={props.connection.data.find(
                  (connection) => connection.connectionId === branchId
                )}
              />

              {props.connection.loading && <PersonnelSuspense />}

              {selectedConnection?.userType === "owner" &&
                !props.connection.loading && (
                  <ChipInputBox
                    name="managers"
                    label="Add Managers"
                    removeResponsive={true}
                    add-data-cy="managers-add-icon"
                    maxLength="0"
                    notes="Type and press Enter to Add managers"
                    chips={
                      isValidObject(props.connection.connectedStaffs)
                        ? Object.values(props.connection.connectedStaffs)
                            .filter((staff) => staff.userType === "manager")
                            .map((staff) => {
                              return {
                                display: staff.staffName,
                                id: staff.profileId,
                                phoneNumber: staff.phoneNumber
                              };
                            })
                        : []
                    }
                    onChipDelete={(phoneNumber) => {
                      props.removeStaff({
                        phoneNumber: phoneNumber,
                        branchId: branchId
                      });
                    }}
                    reDirectOnClick={() => {
                      setShowAddManagerModal(true);
                    }}
                    loading={
                      props.loading === true && activeElement === "managers"
                        ? true
                        : false
                    }
                    onChipAdded={(chips) => {
                      setPersonnelFormData({
                        ...personnelFormData,
                        managers: [...chips]
                      });
                      onOutOfFocus({
                        target: {
                          name: "managers",
                          value: [...chips]
                        }
                      });
                    }}
                    onOutOfFocus={(chips) => {
                      setPersonnelFormData({
                        ...personnelFormData,
                        managers: [...chips]
                      });
                      onOutOfFocus({
                        target: {
                          name: "managers",
                          value: [...chips]
                        }
                      });
                    }}
                  />
                )}
              {(selectedConnection?.userType === "owner" ||
                selectedConnection?.userType === "manager") &&
                !props.connection.loading && (
                  <div className=" padding-top-larger">
                    <ChipInputBox
                      name="staffs"
                      label="Add Staff"
                      add-data-cy="staff-add-icon"
                      removeResponsive={true}
                      maxLength="0"
                      notes="Type and press Enter to Add staffs"
                      chips={
                        isValidObject(props.connection.connectedStaffs)
                          ? Object.values(props.connection.connectedStaffs)
                              .filter((staff) => staff.userType === "staff")
                              .map((staff) => {
                                return {
                                  display: staff.staffName,
                                  id: staff.profileId,
                                  phoneNumber: staff.phoneNumber
                                };
                              })
                          : []
                      }
                      onChipDelete={(phoneNumber) => {
                        props.removeStaff({
                          phoneNumber: phoneNumber,
                          branchId: branchId
                        });
                      }}
                      loading={
                        props.loading === true && activeElement === "staffs"
                          ? true
                          : false
                      }
                      reDirectOnClick={() => {
                        setShowAddStaffModal(true);
                      }}
                      onChipAdded={(chips) => {
                        setPersonnelFormData({
                          ...personnelFormData,
                          staffs: [...chips]
                        });
                        onOutOfFocus({
                          target: {
                            name: "staffs",
                            value: [...chips]
                          }
                        });
                      }}
                      onOutOfFocus={(chips) => {
                        setPersonnelFormData({
                          ...personnelFormData,
                          staffs: [...chips]
                        });
                        onOutOfFocus({
                          target: {
                            name: "staffs",
                            value: [...chips]
                          }
                        });
                      }}
                    />
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
      {/* ADD MANAGER MODAL */}
      <Modal
        show={showAddManagerModal}
        canIgnore={true}
        onClose={() => {
          setShowAddManagerModal(!showAddManagerModal);
          setCurrentStaffData({
            phoneNumber: "+91"
          });
        }}
        position={
          props.windowInnerWidth > 576
            ? ""
            : "position-fixed bottom-0 left-0 right-0"
        }
        width="inherit-parent-width"
        maxWidth={props.windowInnerWidth < 576 ? "" : "max-width-500px"}
        background="false"
        boxShadow="false"
        borderRadius="false"
        height="height-fit-to-content"
      >
        <form
          data-cy="add-manager-modal"
          className={`background-white padding-larger ${
            props.windowInnerWidth < 576
              ? "border-radius-top-default"
              : "border-radius-default"
          } box-shadow-default font-family-gilroy-regular font-color-secondary`}
          onChange={(event) =>
            setCurrentStaffData({
              ...currentStaffData,
              [event.target.name]: event.target.value
            })
          }
          onSubmit={(event) => {
            event.preventDefault();
            props.createNewStaff({
              phoneNumber: currentStaffData.phoneNumber,
              userType: "manager",
              branchId: branchId
            });
            setShowAddManagerModal(!showAddManagerModal);
            setCurrentStaffData({
              phoneNumber: "+91"
            });
          }}
        >
          <div className=" font-size-medium text-align-center padding-bottom-large">
            Add Manager
          </div>
          <div className=" font-size-small text-align-center ">
            Manager can manage staff members & use all the features available
          </div>
          <div className="display-flex flex-grow-1 flex-align-items-center padding-bottom-larger cursor-pointer ">
            <div className="flex-grow-1 flex-direction-column display-flex padding-left-medium  ">
              <InputBox
                data-cy="add-manager-phone-number-field"
                className="inherit-parent-width margin-top-larger"
                label="Phone number"
                type="text"
                name="phoneNumber"
                value={currentStaffData?.phoneNumber}
                autoFocus={true}
                notes="Manager can use this phone number to log in with an OTP"
                removeResponsive={true}
                isValidInput={(data) => {
                  setIsValidPhoneNumber(data);
                }}
              />
            </div>
          </div>
          <div className="display-flex">
            <Button
              data-cy="save-button"
              boxShadow={false}
              loading={props.logoutButtonLoading}
              className="margin-right-small font-family-gilroy-medium"
              variant="primary"
              text="Save"
              disabled={!isValidPhoneNumber}
              type="submit"
            />
            <Button
              boxShadow={false}
              className="margin-left-small font-family-gilroy-medium"
              variant="secondary"
              text="Close"
              onClick={() => {
                setShowAddManagerModal(!showAddManagerModal);
                setCurrentStaffData({
                  phoneNumber: "+91"
                });
              }}
            />
          </div>
        </form>
      </Modal>

      {/* ADD STAFF MODAL */}
      <Modal
        show={showAddStaffModal}
        canIgnore={true}
        onClose={() => {
          setShowAddStaffModal(!showAddStaffModal);
          setCurrentStaffData({
            phoneNumber: "+91"
          });
        }}
        position={
          props.windowInnerWidth > 576
            ? ""
            : "position-fixed bottom-0 left-0 right-0"
        }
        width="inherit-parent-width"
        maxWidth={props.windowInnerWidth < 576 ? "" : "max-width-500px"}
        background="false"
        boxShadow="false"
        borderRadius="false"
        height="height-fit-to-content"
      >
        <form
          data-cy="add-staff-modal"
          className={`background-white padding-larger ${
            props.windowInnerWidth < 576
              ? "border-radius-top-default"
              : "border-radius-default"
          } box-shadow-default font-family-gilroy-regular font-color-secondary`}
          onChange={(event) =>
            setCurrentStaffData({
              ...currentStaffData,
              [event.target.name]: event.target.value
            })
          }
          onSubmit={(event) => {
            event.preventDefault();
            props.createNewStaff({
              phoneNumber: currentStaffData.phoneNumber,
              userType: "staff",
              branchId: branchId
            });
            setShowAddStaffModal(!showAddStaffModal);
            setCurrentStaffData({
              phoneNumber: "+91"
            });
          }}
        >
          <div className=" font-size-medium text-align-center padding-bottom-large">
            Add Staff
          </div>
          <div className=" font-size-small text-align-center ">
            Staff can use all the features available
          </div>
          <div className="display-flex flex-grow-1 flex-align-items-center padding-bottom-larger cursor-pointer ">
            <div className="flex-grow-1 flex-direction-column display-flex padding-left-medium">
              <InputBox
                className="inherit-parent-width margin-top-larger"
                data-cy="add-staff-phone-number-field"
                label="Phone number"
                type="text"
                autoFocus={true}
                value={currentStaffData?.phoneNumber}
                name="phoneNumber"
                notes="Staff can use this phone number to log in with an OTP"
                removeResponsive={true}
                isValidInput={(data) => {
                  setIsValidPhoneNumber(data);
                }}
              />
            </div>
          </div>
          <div className="display-flex">
            <Button
              data-cy="save-button"
              boxShadow={false}
              loading={props.logoutButtonLoading}
              className="margin-right-small font-family-gilroy-medium"
              variant="primary"
              text="Save"
              disabled={!isValidPhoneNumber}
              type="submit"
            />
            <Button
              boxShadow={false}
              className="margin-left-small font-family-gilroy-medium"
              variant="secondary"
              text="Close"
              onClick={() => {
                setShowAddStaffModal(!showAddStaffModal);
                setCurrentStaffData({
                  phoneNumber: "+91"
                });
              }}
              type="button"
            />
          </div>
        </form>
      </Modal>
    </>
  );
}

const mapStateToProps = function (state) {
  return {
    connection: state.connection
  };
};

const mapDispatchToProps = function () {
  return {
    getConnectedStaffs: (data) => connectionActions.getConnectedStaffs(data),
    createNewStaff: (data) => connectionActions.createNewStaff(data),
    removeStaff: (data) => connectionActions.removeStaff(data)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Personnel);

function PersonnelSuspense() {
  return (
    <div>
      {[...Array(2).keys()].map((index) => (
        <div
          key={index}
          className="shimmer margin-top-larger margin-bottom-larger padding-larger border-radius-default min-height-70px"
        />
      ))}
    </div>
  );
}
